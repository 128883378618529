import { useEffect, useState } from "react";
import { auth, db } from "./Firebase";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Button, Fab } from "@mui/material";
import logo from './img/Pikyard.png';
import { Login, Logout, ShoppingBag } from "@mui/icons-material";

export default function Navbar() {

    var navi = useNavigate();
    const [cat, setcat] = useState([]);

    const getCat = () => {
        db.collection("All-Sub-Category").onSnapshot((querySnapshot) => {
            setcat(querySnapshot.docs);
        });
    }

    useEffect(() => {
        getCat();
    }, [])

    const [user, setuser] = useState('');

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setuser(user)
            } else {

            }
        });
    }, [])

    // console.log(user);

    function logout() {
        auth.signOut().then((succ) => {
            window.location.href = "/";
        })
    }

    const [cartcount, setcartcount] = useState(0);

    function getcart() {
        if (user) {
            db.collection("Users").doc(user.uid).collection("Cart").onSnapshot((succ) => {
                setcartcount(succ.size);
            })
        }
    }

    useEffect(() => {
        getcart();
    }, [user])



    return (
        <>
            {/* <!-- Topbar Start --> */}
            <div className="container-fluid">
                {/* <div className="row bg-secondary py-1 px-xl-5">
                    <div className="col-lg-6 d-none d-lg-block">
                        <div className="d-inline-flex align-items-center h-100">
                            <a className="text-body mr-3" href="">About</a>
                            <a className="text-body mr-3" href="">Contact</a>
                            <a className="text-body mr-3" href="">Help</a>
                            <a className="text-body mr-3" href="">FAQs</a>
                        </div>
                    </div>
                    <div className="col-lg-6 text-center text-lg-right">
                        <div className="d-inline-flex align-items-center">
                            <div className="btn-group">
                                <button type="button" className="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown">EN</button>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item" type="button">FR</button>
                                    <button className="dropdown-item" type="button">AR</button>
                                    <button className="dropdown-item" type="button">RU</button>
                                </div>
                            </div>
                        </div>
                        <div className="d-inline-flex align-items-center d-block d-lg-none">
                            <a href="" className="btn px-0 ml-2">
                                <i className="fas fa-heart text-dark"></i>
                                <span className="badge text-dark rounded-circle pb-1">0</span>
                            </a>
                            <a href="" className="btn px-0 ml-2">
                                <i className="fas fa-shopping-cart text-dark"></i>
                                <span className="badge text-dark border border-dark rounded-circle pb-1">0</span>
                            </a>
                        </div>
                    </div>
                </div> */}
                <div className="row align-items-center bg-light py-1 px-xl-5 d-none d-lg-flex">
                    <div className="col-lg-4">
                        <Link to="/" className="text-decoration-none d-flex align-items-center">
                            <span className="h1 text-uppercase text-primary bg-dark px-2">
                                <img src={logo} className="navbar-brand-img" />

                                Pik</span>
                            <span className="h1 text-uppercase text-dark bg-primary px-2 ml-n1">Yard</span>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-6 text-left">
                        <form action="">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search for products" />
                                <div className="input-group-append">
                                    <span className="input-group-text bg-transparent text-primary">
                                        <i className="fa fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-4 col-6 text-right">
                        <p className="m-0">Customer Service +012 345 6789</p>
                        {/* <h5 className="m-0">+012 345 6789</h5> */}
                    </div>
                </div>
            </div>
            {/* <!-- Topbar End --> */}


            {/* <!-- Navbar Start --> */}
            <div className="container-fluid bg-dark mb-30">
                <div className="row px-xl-12">
                    {/* <div className="col-lg-3 d-none d-lg-block">
                        <a className="btn d-flex align-items-center justify-content-between bg-primary w-100 s1" data-toggle="collapse" href="#navbar-vertical">
                            <h6 className="text-dark m-0"><i className="fa fa-bars mr-2"></i>Categories</h6>
                            <i className="fa fa-angle-down text-dark"></i>
                        </a>
                        <nav className="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light s2" id="navbar-vertical">
                            <div className="navbar-nav w-100">
                                {cat.map((row) => (
                                    <a href="" className="nav-item nav-link">{row.data().Category}</a>
                                ))}
                            </div>
                        </nav>
                    </div> */}
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 px-0">
                            <Link to="/" className="text-decoration-none d-lg-none d-flex align-items-center">
                                <img src={logo} className="navbar-brand-img" />
                                {/* <span className="h1 text-uppercase text-primary bg-light px-2">Pik</span>
                                <span className="h1 text-uppercase text-light bg-primary px-2 ml-n1">Yard</span> */}
                            </Link>
                            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse justify-content-between align-items-center" id="navbarCollapse">
                                <div className="navbar-nav mr-auto py-0">
                                    {cat.map((row) => (
                                        <div key={row.id} className="nav-item dropdown">
                                            <a href="#" className="nav-link dropdown-toggle text-capitalize" data-toggle="dropdown">{row.data().Category} <i className="fa fa-angle-down mt-1"></i></a>
                                            <div className="dropdown-menu bg-primary rounded-0 border-0 m-0">
                                                {row.data().Values.map((rpw) => (
                                                    <Button key={rpw} onClick={() => navi("/Products?q=" + rpw)} className="dropdown-item text-capitalize text-light">{rpw}</Button>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                    {/* <a href="index.html" className="nav-item nav-link active">Home</a> */}
                                    {/* <a href="shop.html" className="nav-item nav-link">Shop</a> */}
                                    {/* <a href="detail.html" className="nav-item nav-link">Shop Detail</a> */}
                                    {/* <div className="nav-item dropdown">
                                        <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Pages <i className="fa fa-angle-down mt-1"></i></a>
                                        <div className="dropdown-menu bg-primary rounded-0 border-0 m-0">
                                            <a href="cart.html" className="dropdown-item">Shopping Cart</a>
                                            <a href="checkout.html" className="dropdown-item">Checkout</a>
                                        </div>
                                    </div> */}
                                    {/* <a href="contact.html" className="nav-item nav-link">Contact</a> */}
                                </div>
                                <div className="navbar-nav ml-auto py-0 d-block">
                                    {user ? (
                                        <>
                                            <Link to="/Cart" className="btn btn-secondary rounded  ml-3 mx-2 w100">
                                                <i className="fas fa-shopping-cart text-primary"></i>
                                                <span className="badge pb-1 mx-1">{cartcount}</span>
                                            </Link>
                                            <Link to="/Profile" className="btn btn-secondary rounded mr-2 w100">
                                                <i className="fas fa-user text-primary"></i> Profile
                                            </Link>
                                            <Link to="/Orders" className="btn btn-secondary rounded mr-2 w100">
                                                <i className="fas fa-user text-primary"></i> Orders
                                            </Link>
                                            <button onClick={logout} className="btn btn-light rounded">Logout <Logout /></button>
                                        </>
                                    ) : (
                                        <>
                                            <Link to="/Login" className="btn btn-light rounded w100">
                                                <Login /> Login
                                            </Link>
                                        </>
                                    )}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

            {user && (
                <Link to={'/Cart'}>
                    <Fab color="warning" className="mycart">
                        <Badge badgeContent={cartcount} color="warning">
                            <ShoppingBag className="m-2" />
                        </Badge>
                    </Fab>
                </Link>
            )}


        </>
    )
}