import React, { useEffect, useRef, useState } from "react";
import { Logout, Person, Dashboard, Category, ProductionQuantityLimits, LocalGroceryStore, Menu, Delete, Edit, ArrowCircleRight, Image, Camera } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, Divider, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
// import TopBarAdmin from "./Header";
// import SideBarAdmin from "./Sidebar";
import boiler from './icons/boiler.png'
import firebase from "firebase";
import { db, storage } from "../Firebase";
import imageCompression from "browser-image-compression";
import { Link, useNavigate } from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import HTMLReactParser from "html-react-parser";
import Header from "./Header";
import { styled, useTheme } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));



function ViewOneProduct3() {


    const [prog, setprog] = useState('');

    const [progbar, setprogbar] = useState(false);
    const [btn, setbtn] = useState(true);

    const navigate = useNavigate();

    const [cat, setcat] = React.useState([]);
    const getCat = () => {
        // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
        db.collection("MynewCategory").onSnapshot((querySnapshot) => {
            var allcat = [];
            querySnapshot.forEach((docs) => {
                allcat.push(docs);
            })
            setcat(allcat);
        });
    }
    var id = new URLSearchParams(window.location.search).get('id');

    const [sub, setsub] = React.useState(false);

    const onSubmit = (event) => {
        setsub(true);
        event.preventDefault();
        setsub(false);
    }


    const [data, setdata] = useState();

    const [keywords, setkeywords] = useState([]);
    const [name, setname] = useState('');


    function findProduct() {
        db.collection('All-AllProducts').doc(id).onSnapshot(function (suc) {
            setname(suc.data().Name);
            setkeywords(suc.data().Keywords);
            // console.log(suc.data().Keywords);
        })
    }


    useEffect(() => {
        findProduct();
    }, [id])

    const onSubmit2 = (e) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);

        var newname = data.get('name').toLowerCase();

        keywords.push(newname);

        db.collection('All-AllProducts').doc(id).update({
            Keywords: keywords
        }).then((succ) => {
            alert('added');
            e.target.reset();
            e.target.name.focus();
        })
    }


    const handleDelete = (x) => {
        if (window.confirm("Confirm to Delete")) {
            // alert('Deleted');
            var index = keywords.indexOf(x);
            if (index > -1) {
                keywords.splice(index, 1)
            }

            db.collection('All-AllProducts').doc(id).update({
                Keywords: keywords
            }).then((suc) => {
                alert('Deleted');
            })
        }
    }


    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Header />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    {/* <TopBarAdmin/> */}
                    {/* <Divider/> */}
                    <div className="row">
                        {/* <SideBarAdmin/> */}
                        <div className="col-lg-12">
                            <br />
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-left">
                                    <li class="page-item"><Link class="page-link" to="/AdminProduct">Add New Product</Link></li>
                                    <li class="page-item"><Link class="page-link" to="/AdminProduct/View">View Product</Link></li>
                                </ul>
                            </nav>

                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <ul class="pagination justify-content-center">
                                                <li class="page-item"><Link class="page-link" to={'/Edit?id=' + id}>Edit Product</Link></li>
                                                <li class="page-item"><Link class="page-link" to={'/View?id=' + id}>Add Stock</Link></li>
                                                <li class="page-item active"><Link class="page-link" to={'/Keyword?id=' + id}>Manage Keywords</Link></li>
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <div className="col">
                                                <b>Name -</b> {name}
                                            </div>
                                            <div className="col">
                                                <h6 className="alert alert-danger">Update Keywords</h6>
                                            </div>

                                            <div className="row">

                                                <div className="col-lg-3">
                                                    <form onSubmit={onSubmit2}>
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input type={'text'} className="form-control" name="name" autoFocus />
                                                        </div>
                                                        <Button type="submit" variant="contained">Update</Button>
                                                    </form>
                                                </div>

                                                <div className="col-lg-9">
                                                    {keywords.map((row) => (
                                                        <><Chip label={row} onDelete={() => handleDelete(row)} /> </>
                                                    ))}
                                                </div>

                                                <div >
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default ViewOneProduct3;