import { useEffect, useState } from "react";
import { db } from "./Firebase";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

export default function Home() {

    const [products, setproducts] = useState([]);

    function getlatest() {
        db.collection("All-AllProducts").limit(4).get().then((succ) => {
            setproducts(succ.docs);
        })
    }
    useEffect(() => {
        getlatest();
    }, [])

    return (
        <>

            <Navbar />

            {/* <!-- Carousel Start --> */}
            <div class="container-fluid mb-3">
                <div class="row px-xl-5">
                    <div class="col-lg-8">
                        <div id="header-carousel" class="carousel slide carousel-fade mb-30 mb-lg-0" data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li data-target="#header-carousel" data-slide-to="0" class="active"></li>
                                <li data-target="#header-carousel" data-slide-to="1"></li>
                                <li data-target="#header-carousel" data-slide-to="2"></li>
                            </ol>
                            <div class="carousel-inner">
                                <div class="carousel-item position-relative active s3">
                                    <img class="position-absolute w-100 h-100" src="img/carousel-1.jpg" />
                                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                        <div class="p-3 s5">
                                            <h1 class="display-4 text-white mb-3 animate__animated animate__fadeInDown">Men Fashion</h1>
                                            <p class="mx-md-5 px-5 animate__animated animate__bounceIn">Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam</p>
                                            {/* <a class="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp" href="#">Shop Now</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item position-relative s3">
                                    <img class="position-absolute w-100 h-100 s4" src="img/carousel-2.jpg" />
                                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                        <div class="p-3 s5">
                                            <h1 class="display-4 text-white mb-3 animate__animated animate__fadeInDown">Women Fashion</h1>
                                            <p class="mx-md-5 px-5 animate__animated animate__bounceIn">Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam</p>
                                            {/* <a class="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp" href="#">Shop Now</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item position-relative s3">
                                    <img class="position-absolute w-100 h-100 s4" src="img/carousel-3.jpg" />
                                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                        <div class="p-3 s5">
                                            <h1 class="display-4 text-white mb-3 animate__animated animate__fadeInDown">Kids Fashion</h1>
                                            <p class="mx-md-5 px-5 animate__animated animate__bounceIn">Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam</p>
                                            {/* <a class="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp" href="#">Shop Now</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-none d-lg-block">
                        <div class="product-offer mb-30 s6">
                            <img class="img-fluid" src="img/offer-1.jpg" alt="" />
                            <div class="offer-text">
                                <h6 class="text-white text-uppercase">Save 20%</h6>
                                <h3 class="text-white mb-3">Special Offer</h3>
                                {/* <a href="" class="btn btn-primary">Shop Now</a> */}
                            </div>
                        </div>
                        <div class="product-offer mb-30 s6">
                            <img class="img-fluid" src="img/offer-2.jpg" alt="" />
                            <div class="offer-text">
                                <h6 class="text-white text-uppercase">Save 20%</h6>
                                <h3 class="text-white mb-3">Special Offer</h3>
                                {/* <a href="" class="btn btn-primary">Shop Now</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Carousel End --> */}


            {/* <!-- Featured Start --> */}
            <div class="container-fluid pt-5">
                <div class="row px-xl-5 pb-3">
                    <div class="col-lg-3 col-md-6 col-sm-6 col-6 pb-1">
                        <div class="d-flex align-items-center bg-light mb-4 p30">
                            <h1 class="fa fa-check text-primary m-0 mr-3"></h1>
                            <h5 class="font-weight-semi-bold m-0">Quality Product</h5>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-6 pb-1">
                        <div class="d-flex align-items-center bg-light mb-4 p30">
                            <h1 class="fa fa-shipping-fast text-primary m-0 mr-2"></h1>
                            <h5 class="font-weight-semi-bold m-0">Free Shipping</h5>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-6 pb-1">
                        <div class="d-flex align-items-center bg-light mb-4 p30">
                            <h1 class="fas fa-exchange-alt text-primary m-0 mr-3"></h1>
                            <h5 class="font-weight-semi-bold m-0">14-Day Return</h5>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 col-6 pb-1">
                        <div class="d-flex align-items-center bg-light mb-4 p30">
                            <h1 class="fa fa-phone-volume text-primary m-0 mr-3"></h1>
                            <h5 class="font-weight-semi-bold m-0">24/7 Support</h5>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Featured End --> */}


            {/* <!-- Categories Start --> */}
            {/* <div class="container-fluid pt-5"> */}
            {/* <h2 class="section-title position-relative text-uppercase mx-xl-5 mb-4"><span class="bg-secondary pr-3">Categories</span></h2> */}
            {/* <div class="row px-xl-5 pb-3"> */}
            {/* <div class="col-lg-3 col-md-4 col-sm-6 pb-1">
                        <a class="text-decoration-none" href="">
                            <div class="cat-item img-zoom d-flex align-items-center mb-4">
                                <div class="overflow-hidden s8">
                                    <img class="img-fluid" src="img/cat-3.jpg" alt="" />
                                </div>
                                <div class="flex-fill pl-3">
                                    <h6>Category Name</h6>
                                    <small class="text-body">100 Products</small>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div> */}
            {/* <!-- Categories End --> */}


            {/* <!-- Products Start --> */}
            <div class="container-fluid pt-5 pb-3">
                <h2 class="section-title position-relative text-uppercase mx-xl-5 mb-4"><span class="bg-secondary pr-3">Featured Products</span></h2>
                <div class="row px-xl-5">
                    {products.map((row) => (
                        <div key={row.id} className="col-lg-3 col-md-4 col-sm-6 col-6 pb-1">
                            <div className="product-item bg-light mb-4">
                                <div className="product-img position-relative overflow-hidden text-center d-flex aling-items-center">
                                    <img className="img-fluid m-auto" src={row.data().images[0]} alt="" />
                                    <div className="product-action">
                                        {/* <a className="btn btn-outline-dark btn-square" href=""><i className="fa fa-shopping-cart"></i></a> */}
                                        <a className="btn btn-outline-dark btn-square"><i className="far fa-heart"></i></a>
                                        {/* <a className="btn btn-outline-dark btn-square" href=""><i className="fa fa-sync-alt"></i></a> */}
                                        <Link className="btn btn-outline-dark btn-square" to={"/ProductDetail?p=" + row.id}><i className="fa fa-search"></i></Link>
                                    </div>
                                </div>
                                <div className="text-center py-4">
                                    <p className="h6 text-decoration-none text-truncate text-capitalize">{row.data().Name.slice(0, 30)}...</p>
                                    <div className="d-flex align-items-center justify-content-center mt-2">
                                        <h5>Min Rs.{row.data().Avg_price[0]}</h5>
                                        {/* <h6 className="text-muted ml-2">
                                            <del>$123.00</del>
                                            </h6> */}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center mb-1">
                                        <small className="fa fa-star text-primary mr-1"></small>
                                        <small className="fa fa-star text-primary mr-1"></small>
                                        <small className="fa fa-star text-primary mr-1"></small>
                                        <small className="far fa-star text-primary mr-1"></small>
                                        <small className="far fa-star text-primary mr-1"></small>
                                        {/* <small>(99)</small> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* <div class="col-lg-3 col-md-4 col-sm-6 pb-1">
                        <div class="product-item bg-light mb-4">
                            <div class="product-img position-relative overflow-hidden">
                                <img class="img-fluid w-100" src="img/product-8.jpg" alt="" />
                                <div class="product-action">
                                    <a class="btn btn-outline-dark btn-square" href=""><i class="fa fa-shopping-cart"></i></a>
                                    <a class="btn btn-outline-dark btn-square" href=""><i class="far fa-heart"></i></a>
                                    <a class="btn btn-outline-dark btn-square" href=""><i class="fa fa-sync-alt"></i></a>
                                    <a class="btn btn-outline-dark btn-square" href=""><i class="fa fa-search"></i></a>
                                </div>
                            </div>
                            <div class="text-center py-4">
                                <a class="h6 text-decoration-none text-truncate" href="">Product Name Goes Here</a>
                                <div class="d-flex align-items-center justify-content-center mt-2">
                                    <h5>$123.00</h5><h6 class="text-muted ml-2"><del>$123.00</del></h6>
                                </div>
                                <div class="d-flex align-items-center justify-content-center mb-1">
                                    <small class="fa fa-star text-primary mr-1"></small>
                                    <small class="fa fa-star text-primary mr-1"></small>
                                    <small class="fa fa-star text-primary mr-1"></small>
                                    <small class="far fa-star text-primary mr-1"></small>
                                    <small class="far fa-star text-primary mr-1"></small>
                                    <small>(99)</small>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* <!-- Products End --> */}


            {/* <!-- Offer Start --> */}
            <div class="container-fluid pt-5 pb-3 d-block d-lg-none">
                <div class="row px-xl-5">
                    <div class="col-md-6">
                        <div class="product-offer mb-30 s7">
                            <img class="img-fluid" src="img/offer-1.jpg" alt="" />
                            <div class="offer-text">
                                <h6 class="text-white text-uppercase">Save 20%</h6>
                                <h3 class="text-white mb-3">Special Offer</h3>
                                {/* <a href="" class="btn btn-primary">Shop Now</a> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="product-offer mb-30 s7">
                            <img class="img-fluid" src="img/offer-2.jpg" alt="" />
                            <div class="offer-text">
                                <h6 class="text-white text-uppercase">Save 20%</h6>
                                <h3 class="text-white mb-3">Special Offer</h3>
                                {/* <a href="" class="btn btn-primary">Shop Now</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Offer End --> */}


            {/* <!-- Products Start --> */}
            {/* <div class="container-fluid pt-5 pb-3"> */}
            {/* <h2 class="section-title position-relative text-uppercase mx-xl-5 mb-4"><span class="bg-secondary pr-3">Recent Products</span></h2> */}
            {/* <div class="row px-xl-5"> */}
            {/* <div class="col-lg-3 col-md-4 col-sm-6 pb-1">
                        <div class="product-item bg-light mb-4">
                            <div class="product-img position-relative overflow-hidden">
                                <div class="product-action">
                                    <a class="btn btn-outline-dark btn-square" href=""><i class="fa fa-shopping-cart"></i></a>
                                    <a class="btn btn-outline-dark btn-square" href=""><i class="far fa-heart"></i></a>
                                    <a class="btn btn-outline-dark btn-square" href=""><i class="fa fa-sync-alt"></i></a>
                                    <a class="btn btn-outline-dark btn-square" href=""><i class="fa fa-search"></i></a>
                                </div>
                            </div>
                            <div class="text-center py-4">
                                <a class="h6 text-decoration-none text-truncate" href="">Product Name Goes Here</a>
                                <div class="d-flex align-items-center justify-content-center mt-2">
                                    <h5>$123.00</h5><h6 class="text-muted ml-2"><del>$123.00</del></h6>
                                </div>
                                <div class="d-flex align-items-center justify-content-center mb-1">
                                    <small class="fa fa-star text-primary mr-1"></small>
                                    <small class="fa fa-star text-primary mr-1"></small>
                                    <small class="fa fa-star text-primary mr-1"></small>
                                    <small class="far fa-star text-primary mr-1"></small>
                                    <small class="far fa-star text-primary mr-1"></small>
                                    <small>(99)</small>
                                </div>
                            </div>
                        </div>
                    </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* <!-- Products End --> */}


            {/* <!-- Vendor Start --> */}
            <div class="container-fluid py-5">
                <div class="row px-xl-5">
                    <div class="col">
                        <div class="owl-carousel vendor-carousel">
                            <div class="bg-light p-4">
                                <img src="img/vendor-1.jpg" alt="" />
                            </div>
                            <div class="bg-light p-4">
                                <img src="img/vendor-2.jpg" alt="" />
                            </div>
                            <div class="bg-light p-4">
                                <img src="img/vendor-3.jpg" alt="" />
                            </div>
                            <div class="bg-light p-4">
                                <img src="img/vendor-4.jpg" alt="" />
                            </div>
                            <div class="bg-light p-4">
                                <img src="img/vendor-5.jpg" alt="" />
                            </div>
                            <div class="bg-light p-4">
                                <img src="img/vendor-6.jpg" alt="" />
                            </div>
                            <div class="bg-light p-4">
                                <img src="img/vendor-7.jpg" alt="" />
                            </div>
                            <div class="bg-light p-4">
                                <img src="img/vendor-8.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Vendor End --> */}

            <Footer />

            {/* <!-- Back to Top --> */}
            <a href="#" class="btn btn-primary back-to-top"><i class="fa fa-angle-double-up"></i></a>


        </>
    )
}