import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBZrEPsxM2T1EZZMMCCuv7S23f2RhzvlGo",
  authDomain: "pikyard-01.firebaseapp.com",
  databaseURL: "https://pikyard-01-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "pikyard-01",
  storageBucket: "pikyard-01.appspot.com",
  messagingSenderId: "1095484338329",
  appId: "1:1095484338329:web:65175f01d7dd438c5b0808"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage();
const realdb = firebase.database();
const auth = firebase.auth();
const provider  = new firebase.auth.GoogleAuthProvider();

export {db, storage, realdb, auth, provider}