import React, { useEffect, useRef, useState } from "react";
import TopBarAdmin from "./Header";
import firebase from "firebase";
import { db, storage } from "../Firebase";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ArrowRight, Delete } from "@mui/icons-material";

function Orderslist(){

    const navi = useNavigate();

    const [order,setorder] = useState([]);
    function orders(){
        db.collection('FinalOrders').onSnapshot((succ) => {
            var ar =[];
            succ.forEach((succc) => {
                ar.push(succc);
            })
            setorder(ar);
        })
    }


    useEffect(() => {
        orders();
    }, [])

    function vieworder(x){
        var path = '/Vieworder?id='+x;
        navi(path)
    }

    // function del(x){
    //     db.collection('User').doc(x).delete();
    // }

    return(
        <>
        <TopBarAdmin/>
        <Divider/>
            <Card>
            <CardContent>

            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>OID</TableCell>
                        <TableCell>Payment</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Products Count</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {order.map((row) => (
                        <TableRow key={row.id}>
                        <TableCell>{row.data().Oid}</TableCell>
                        <TableCell>{row.data().Payment}</TableCell>
                        <TableCell>{row.data().FullDate}</TableCell>
                        <TableCell>{row.data().Products.length}</TableCell>
                        <TableCell>
                            {row.data().orderStatus == 0 && ('Order Placed')}
                            {row.data().orderStatus == 1 && ('Order On the way')}
                            {row.data().orderStatus == 2 && ('Order Delivered')}
                        </TableCell>
                        <TableCell align="center">
                            <Button onClick={() => vieworder(row.id)} variant="contained"><ArrowRight/></Button>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>

                </CardContent>

            </Card>
        </>
    )
}

export default Orderslist;