import React, { useEffect, useRef, useState } from "react";
import { Logout, Person, Dashboard, Category, ProductionQuantityLimits, LocalGroceryStore, Menu, Delete, Edit, ArrowCircleRight, Image, Camera } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
// import TopBarAdmin from "./Header";
// import SideBarAdmin from "./Sidebar";
import boiler from './icons/boiler.png'
import firebase from "firebase";
import { db, storage } from "../Firebase";
import imageCompression from "browser-image-compression";
import { Link, useNavigate } from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import HTMLReactParser from "html-react-parser";

import Header from "./Header";
import { styled, useTheme } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


function ViewOneProduct() {


  const [prog, setprog] = useState('');

  const [progbar, setprogbar] = useState(false);
  const [btn, setbtn] = useState(true);

  const navigate = useNavigate();

  const [cat, setcat] = React.useState([]);
  const getCat = () => {
    // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
    db.collection("MynewCategory").onSnapshot((querySnapshot) => {
      var allcat = [];
      querySnapshot.forEach((docs) => {
        allcat.push(docs);
      })
      setcat(allcat);
    });
  }
  var id = new URLSearchParams(window.location.search).get('id');

  const [sub, setsub] = React.useState(false);

  const onSubmit = (event) => {
    setsub(true);
    event.preventDefault();
    setsub(false);
  }


  const [data, setdata] = useState();

  const [name, setname] = useState('');
  const [brand, setbrand] = useState('');
  const [description, setdescription] = useState('');
  const [allimages, setimages] = useState([]);
  const [alladdto, setaddto] = useState([]);
  const [pid, setpid] = useState();


  function findProduct() {
    db.collection('All-AllProducts').doc(id).onSnapshot(function (suc) {
      setname(suc.data().Name);
      setbrand(suc.data().Brand);
      setdescription(suc.data().Description);
      setimages(suc.data().images);
      setaddto(suc.data().addtocart);
      setpid(suc.data().pid);
    })
  }


  useEffect(() => {
    findProduct();
  }, [id])

  const [subsubcat, setsubsubcat] = React.useState([]);
  const [subsubcatlist, setsubsubcatlist] = React.useState([]);
  const getsubsubCat = () => {
    // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
    db.collection("Sub-Sub-Category").onSnapshot((querySnapshot) => {
      var allsubsubcat = [];
      var allsubsubcatlist = [];
      querySnapshot.forEach((docs) => {
        allsubsubcat.push(docs);
        // console.log(docs.data().Values);
        allsubsubcatlist.push(docs.data().Values);
        // console.log(allsubsubcatlist);
      })
      setsubsubcat(allsubsubcat);
      setsubsubcatlist(allsubsubcatlist);
    });
  }


  // console.log(allimages);
  // if(allimages){
  //   console.log('yes');
  // }else{
  //   console.log('no');
  // }

  const onSubmit2 = (event) => {
    var batch = db.batch();
    // setsub(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // var category = data.get('category');
    var mrp = data.get('mrp');
    var selling = data.get('selling');
    var percent = data.get('percent');
    var material = data.get('material');
    var size = data.get('size')
    var description = data.get('description');
    var image = data.get('image1');
    var stock = data.get('stock');

    // console.log(percent);

    // console.log(selling);

    if (percent == '') {
      // console.log(mrp);
      // console.log(selling);

      var newpercent = (parseFloat(selling) * 100) / parseFloat(mrp);
      // console.log(parseFloat(100-newpercent));

      var percent = 100 - newpercent;

    } else {
      // console.log(selling);
      var newselling = (parseFloat(percent) / 100) * mrp;
      var selling = mrp - newselling;

    }

    selling = (parseFloat(selling).toFixed(2));

    percent = parseFloat(percent);

    console.log(pid);

    db.collection('All-AllProducts').doc(id).collection('Stock').orderBy('date', 'desc').limit(1).get().then(function (suc) {
      var ord = 1;
      suc.forEach(function (succ) {
        if (succ.exists) {
          ord = succ.data().ono + 1;
        }
      })

      var str = "" + ord
      var pad = "0000"
      var ans = pad.substring(0, pad.length - str.length) + str;
      var newpid = pid + '-' + ans;

      // console.log(newpid);

      var metadata = {
        contentType: 'image/jpeg'
      };

      var options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      imageCompression(image, options).then(function (newimage) {



        var uploadTask = storage.ref().child('images/' + id + '/' + image.name).put(image, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

              var query = db.collection("All-AllProducts").doc(id)
              var img;
              // if(allimages){
              // allimages.push(downloadURL);
              // }else{
              //   setimages([downloadURL]);
              // }

              // batch.update(query,{
              //     varient:firebase.firestore.FieldValue.increment(1),
              //     images:allimages
              // })

              // console.log(mrp)
              // console.log(selling)
              // console.log(material)
              // console.log(size)
              // console.log(downloadURL)
              // console.log(stock)

              var query2 = query.collection('Stock').doc()

              batch.update(query2, {
                Avg_price: firebase.firestore.FieldValue.arrayUnion(Number(parseFloat(selling).toFixed(2)))
              })

              batch.set(query2, {
                MRP: parseFloat(mrp).toFixed(2),
                Selling: parseFloat(selling).toFixed(2),
                Percentage: parseFloat(percent),
                Material: material,
                Size: size,
                Image: [downloadURL],
                Stock: parseInt(stock),
                ono: ord,
                vid: newpid,
                date: firebase.firestore.FieldValue.serverTimestamp()
              })
              console.log(id);

              batch.commit().then((suc) => {
                console.log('yes');
                event.target.reset();
                setsub(false);
                alert('Stock Added');
              })

            });
          }
        );
      })

    })

  }


  const [allstock, setallstock] = React.useState([]);

  const getStock = () => {
    db.collection('All-AllProducts').doc(id).collection('Stock').onSnapshot(function (succ) {
      var ar = [];
      succ.forEach(function (succc) {
        ar.push(succc);
      })
      setallstock(ar);
    })
  }

  function delPro(x) {
    // alert('hi')
    let text = "Press Ok to Confirm.";
    if (window.confirm(text) == true) {
      // text = "You pressed OK!";
      db.collection("All-AllProducts").doc(id).collection('Stock').doc(x.id).delete();
      storage.refFromURL(x.data().Image).delete();
    } else {
      // text = "You canceled!";
    }

  }

  function editPro(x) {
    var path = '/StockEdit?pid=' + id + '&id=' + x.id;
    navigate(path)
    // db.collection("All-AllProducts").doc(id).collection('Stock').doc(x.id).delete();
    // storage.refFromURL(x.data().Image).delete();
  }


  const inputFileRef = useRef(null);

  function opencamera() {
    inputFileRef.current.click();
  }

  const [nimg, setnimg] = useState();

  function setnewimg(x) {
    console.log(x);
    setnimg(x);
  }


  React.useEffect(() => {
    getCat();
    getsubsubCat()
    getStock()
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />

          {/* <TopBarAdmin /> */}
          {/* <Divider /> */}
          <div className="row">
            {/* <SideBarAdmin/> */}
            <div className="col-lg-12">
              <br />
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-left">
                  <li class="page-item"><Link class="page-link" to="/AdminProduct">Add New Product</Link></li>
                  <li class="page-item"><Link class="page-link" to="/AdminProduct/View">View Product</Link></li>
                </ul>
              </nav>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">

                      <ul class="pagination justify-content-center">
                        <li class="page-item"><Link class="page-link" to={'/Edit?id=' + id}>Edit Product</Link></li>
                        <li class="page-item active"><Link class="page-link" to={'/View?id=' + id}>Add Stock</Link></li>
                        <li class="page-item"><Link class="page-link" to={'/Keyword?id=' + id}>Manage Keywords</Link></li>
                      </ul>

                    </div>
                    <div className="card-body">
                      <div className="col">
                        <h5 style={{ textTransform: 'capitalize' }}>Add Stock {name}</h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">

                          <form onSubmit={onSubmit2} encType="multipart/form-data" className='row'>
                            <div className="col-lg-2" style={{ padding: 3 }}>
                              <small>MRP</small>
                              <TextField InputLabelProps={{ shrink: true, }} type='number' name='mrp' id='mrp' margin='normal' fullWidth size="small" label="MRP" required />
                            </div>
                            <div className="col-lg-2" style={{ padding: 3 }}>
                              <small>Off %</small>
                              <TextField InputLabelProps={{ shrink: true, }} type='number' name='percent' id='percent' margin='normal' fullWidth size="small" label="Off %" />
                            </div>
                            <div className="col-lg-2" style={{ padding: 3 }}>
                              <small>Selling Price</small>
                              <TextField InputLabelProps={{ shrink: true, }} type='number' name='selling' id='selling' margin='normal' fullWidth size="small" label="Selling Price" />
                            </div>
                            <div className="col-lg-2" style={{ padding: 3 }}>
                              <small>Material</small>
                              <TextField InputLabelProps={{ shrink: true, }} type='text' name='material' id='material' margin='normal' fullWidth size="small" label="Material" required />
                            </div>
                            <div className="col-lg-2" style={{ padding: 3 }}>
                              <small>Spcify Size</small>
                              <TextField InputLabelProps={{ shrink: true, }} type='text' name='size' id='size' margin='normal' fullWidth size="small" label="Size" required />
                            </div>

                            <div className="col-lg-2" style={{ padding: 3 }}>
                              <small>Stock</small>
                              <TextField
                                type="number"
                                size="small"
                                fullWidth
                                id="stock"
                                name="stock"
                                variant="outlined"
                                margin='normal'
                                required
                                label="Stock"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>

                            <div className="col-lg-12" style={{ padding: 3 }}>

                              {/* <SunEditor height='200px' name='description' placeholder='Describe Product Here'  onChange={(e) => setdescription(e.target.value)} defaultValue='' ></SunEditor> */}
                              <small>Product Image</small>
                              <TextField
                                type="file"
                                size="small"
                                fullWidth
                                id="image"
                                name="image1"
                                variant="outlined"
                                margin='normal'
                                required
                                label="Image"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                            <div className="col-lg-12">
                              {sub ? (
                                <>
                                  Uploading <CircularProgress />
                                </>
                              ) : (
                                <Button variant='contained' type="submit">Submit</Button>
                              )}
                            </div>
                          </form>


                          <div className="row">

                            <TableContainer>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow style={{ fontWeight: 'bold' }}>
                                    <TableCell>Pid</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>MRP</TableCell>
                                    <TableCell>Selling Price</TableCell>
                                    <TableCell>Off Price %</TableCell>
                                    <TableCell>Material</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell>Stock</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {allstock.map((row) => (
                                    <TableRow key={row.id}>
                                      <TableCell>{row.data().vid}</TableCell>
                                      <TableCell><img src={row.data().Image[0]} width='100px' /></TableCell>
                                      <TableCell>{row.data().MRP}</TableCell>
                                      <TableCell>{row.data().Selling ? ('' + row.data().Selling + '') : ('' + parseFloat(row.data().MRP) - ((parseFloat(row.data().Percentage) / 100) * parseFloat(row.data().MRP)) + '')}</TableCell>
                                      <TableCell>{row.data().Percentage ? ('' + row.data().Percentage + '%') : ('0%')}</TableCell>
                                      <TableCell>{row.data().Material}</TableCell>
                                      <TableCell>{row.data().Size}</TableCell>
                                      <TableCell>{row.data().Stock}</TableCell>
                                      <TableCell>

                                        <Button onClick={() => delPro(row)} size="small" variant='contained' color='warning'><Delete /></Button>
                                        <Button onClick={() => editPro(row)} size="small" variant='contained' color='success'><Edit /></Button>

                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>




                          </div>




                        </div>



                      </div>



                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default ViewOneProduct;