import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { db } from "./Firebase";
import firebase from "firebase";
import { useEffect, useState } from "react";

export default function Products() {

    var catid = new URLSearchParams(useLocation().search).get("q");

    const [products, setproducts] = useState([]);
    function getproducts() {
        if (catid) {
            db.collection("All-AllProducts").where("Category", "==", catid).get().then((succ) => {
                // db.collection("All-AllProducts").get().then((succ) => {
                setproducts(succ.docs);
                //     console.log(succ.docs.length);
                //     succ.docs.map((rows) => {
                // var qr = db.collection("All-AllProducts").doc(rows.id)

                // qr.collection("Stock").get().then((rocks) => {
                // console.log(rocks.docs);
                // var avpr = [];
                // rocks.docs.map((roxs) => {
                //     avpr.push(Number(roxs.data().Selling))
                // })
                // console.log(avpr);
                // qr.update({
                //     Avg_price: avpr
                // })
                // })
                // })
            })
        }
    }

    useEffect(() => {
        getproducts();
    }, [catid])


    return (
        <>

            <Navbar />

            <div className="container-fluid pt-5 pb-3">
                {/* <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4"><span className="bg-secondary pr-3">Featured Products</span></h2> */}
                <div className="row px-xl-5">
                    {products.length == 0 && (
                        <div className="col-lg-12">
                            <p className="text-center">No Product Available for {catid}</p>
                        </div>
                    )}
                    {products.map((row) => (
                        <div key={row.id} className="col-lg-3 col-md-4 col-sm-6 pb-1">
                            <div className="product-item bg-light mb-4">
                                <div className="product-img position-relative overflow-hidden text-center d-flex aling-items-center">
                                    <img className="img-fluid m-auto" src={row.data().images[0]} alt="" />
                                    <div className="product-action">
                                        {/* <a className="btn btn-outline-dark btn-square" href=""><i className="fa fa-shopping-cart"></i></a> */}
                                        <Link className="btn btn-outline-dark btn-square"><i className="far fa-heart"></i></Link>
                                        {/* <a className="btn btn-outline-dark btn-square" href=""><i className="fa fa-sync-alt"></i></a> */}
                                        <Link className="btn btn-outline-dark btn-square" to={"/ProductDetail?p=" + row.id}><i className="fa fa-search"></i></Link>
                                    </div>
                                </div>
                                <div className="text-center py-4">
                                    <p className="h6 text-decoration-none text-truncate text-capitalize">{row.data().Name.slice(0, 30)}...</p>
                                    <div className="d-flex align-items-center justify-content-center mt-2">
                                        <h5>Min Rs.{row.data().Avg_price[0]}</h5>
                                        {/* <h6 className="text-muted ml-2">
                                            <del>$123.00</del>
                                            </h6> */}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center mb-1">
                                        <small className="fa fa-star text-primary mr-1"></small>
                                        <small className="fa fa-star text-primary mr-1"></small>
                                        <small className="fa fa-star text-primary mr-1"></small>
                                        <small className="far fa-star text-primary mr-1"></small>
                                        <small className="far fa-star text-primary mr-1"></small>
                                        {/* <small>(99)</small> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* <!-- Products End --> */}

            <Footer />

            {/* <!-- Back to Top --> */}
            <a href="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></a>


        </>
    )
}