import React, { useState } from "react";
import { Logout, Person, Dashboard, Category, ProductionQuantityLimits, LocalGroceryStore, Menu, Delete, Edit, ArrowCircleRight } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, Stack, Switch, TextField, Typography } from "@mui/material";
// import TopBarAdmin from "./Header";
// import SideBarAdmin from "./Sidebar";
import boiler from './icons/boiler.png'
import firebase from "firebase";
import { db, storage } from "../Firebase";
import imageCompression from "browser-image-compression";
import { Link, useNavigate } from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import Header from "./Header";
import { styled, useTheme } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));



function ViewProduct() {


  const [prog, setprog] = useState('');

  const [progbar, setprogbar] = useState(false);
  const [btn, setbtn] = useState(true);

  const navigate = useNavigate();

  const [cat, setcat] = React.useState([]);
  const getCat = () => {
    // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
    db.collection("MynewCategory").onSnapshot((querySnapshot) => {
      var allcat = [];
      querySnapshot.forEach((docs) => {
        allcat.push(docs);
      })
      setcat(allcat);
    });
  }

  const [sub, setsub] = React.useState(false);

  const onSubmit = (event) => {
    // setsub(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var category = data.get('category').toLowerCase();
    var name = data.get('name').toLowerCase();
    var brand = data.get('brand').toLowerCase();
    var description = data.get('description');
    var sellercode = data.get('sellercode');
    var tax = data.get('tax');
    var checked = data.get('checked');
    var image = data.get('image');
    var weight = data.get('weight');
    var hsn = data.get('hsn');

    var batch = db.batch();
    var d = new Date();

    db.collection('All-AllProducts').orderBy('date', 'desc').limit(1).get().then(function (suc) {
      var ord = 1;
      suc.forEach(function (succ) {
        if (succ.exists) {
          ord = succ.data().ono + 1;
        }
      })

      // category.split("|")[2]

      var str = "" + ord
      var pad = "0000"
      var ans = pad.substring(0, pad.length - str.length) + str
      // var yer = d.getFullYear().toString();
      var yer = d.getFullYear();
      // console.log(yer);
      var y = yer.substring(2, 4);
      var oid = "PID" + category.split("|")[2] + ans;
      var dd = String(d.getDate()).padStart(2, '0');
      var mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = d.getFullYear();
      var today = dd + '/' + mm + '/' + yyyy;
      var year = d.getFullYear();
      var month = (d.getMonth() + 1);

      // console.log(oid);

      // console.log(checked);
      var ct = category.split("|")[1];
      // console.log(ct)
      db.collection('All-Sub-Sub-Category').doc(ct).get().then(function (succ) {
        var newbrand = [];
        if (brand != 'na' || brand != '') {

          var brands = '';
          if (succ.data().Brands) {
            // console.log('yes');
            brands = newbrand.concat(succ.data().Brands, [brand]);
          } else {
            // console.log('no');
            brands = [brand];
          }

          var setct = db.collection('All-Sub-Sub-Category').doc(ct)

          // batch.update(setct, {
          //   Brands:brands
          // })
          // console.log(brand)
        }


        var options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1000,
          useWebWorker: true
        }

        var metadata = {
          contentType: 'image/jpeg'
        };

        // console.log(category.split("|"));

        var random = Math.floor(Math.random() * (1000000000 - 100000000)) + 100000000;


        var uploadTask = storage.ref().child('images/' + random + '/' + image.name).put(image, metadata);

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            switch (error.code) {
              case 'storage/unauthorized':
                break;
              case 'storage/canceled':
                break;
              case 'storage/unknown':
                break;
            }
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              // console.log('image1 available at', downloadURL);
              const ar = name.split(' ');
              ar.push(brand);
              ar.push(category.split("|")[0])
              ar.push(category.split("|")[1])
              ar.push(category.split("|")[2])
              ar.push(oid)
              ar.push(weight)
              ar.push(hsn)
              ar.push(sellercode)

              var addtocart = false;
              if (checked) {
                // console.log('yes');
                addtocart = true;
              } else {
                // console.log('no')
                addtocart = false;
              }

              var pp = db.collection("All-AllProducts").doc()

              batch.set(pp, {
                Name: name,
                Brand: brand,
                Description: description,
                SubCategory: category.split("|")[0],
                Category: category.split("|")[1],
                addtocart: addtocart,
                images: [downloadURL],
                varient: 0,
                Keywords: ar,
                sellercode: sellercode,
                tax: tax,
                date: firebase.firestore.FieldValue.serverTimestamp(),
                newdate: d,
                month: month,
                year: year,
                pid: oid,
                todate: today,
                ono: ord,
                Weight: weight,
                hsn: hsn
              })

              // .then((suc) => {
              // console.log('yes');
              // event.target.reset();
              // data.set('description', '');
              // setsub(false);
              // var path = '/View?id='+suc.id;
              // navigate(path)
              // })

              // console.log(pp.id);

              batch.commit().then(function (succ) {

                // console.log('yes');
                event.target.reset();
                data.set('description', '');
                setsub(false);
                var path = '/View?id=' + pp.id;
                navigate(path)
              })
            })
          })
      })

    })

  }

  const [subsubcat, setsubsubcat] = React.useState([]);
  const [subsubcatlist, setsubsubcatlist] = React.useState([]);
  const [allbrands, setallbrands] = React.useState([]);
  const getsubsubCat = () => {
    // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
    db.collection("All-Sub-Sub-Category").onSnapshot((querySnapshot) => {
      var allsubsubcat = [];
      var allsubsubcatlist = [];
      var allbrand = [];
      querySnapshot.forEach((docs) => {
        allsubsubcat.push(docs);
        allsubsubcatlist.push(docs.data().Values);
        // var a = docs.data().Brands.toString();
        // var a = docs.data().Brands;
        // console.log(docs.data().Brands);
        // console.log(docs.data().Brands.toString());
      })
      setsubsubcat(allsubsubcat);
      setsubsubcatlist(allsubsubcatlist);
      setallbrands(allbrand);
    });
  }

  // console.log(allbrands);




  const [image1, setimage1] = useState();

  // console.log(URL.createObjectURL(image1))

  const [Gbrand, setGbrand] = useState([]);
  function globalbrands() {
    db.collection('Brands').doc('mybrands').onSnapshot(function (succ) {
      if (succ.exists) {
        setGbrand(succ.data().Value);
      } else {
        setGbrand('no');
      }
    })
  }



  React.useEffect(() => {
    getCat();
    getsubsubCat()
    globalbrands();
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />

          {/* <TopBarAdmin /> */}
          {/* <Divider /> */}
          <div className="row">
            {/* <SideBarAdmin/> */}
            <div className="col-lg-12">
              <br />
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-left">
                  <li class="page-item active"><Link class="page-link" to="/AdminProduct">Add Product</Link></li>
                  <li class="page-item"><Link class="page-link" to="/AdminProduct/View">View Product</Link></li>
                </ul>
              </nav>

              <div className="row">

                <div className="col-lg-8" style={{ margin: 'auto' }}>
                  <div className="card">
                    <div className="card-body">
                      <div className="col">
                        <h6>Add Products</h6>
                      </div>
                      <div className="row">

                        <div className="col-lg-12">

                          <form onSubmit={onSubmit} encType="multipart/form-data" className="row">

                            <div className="col-lg-12">
                              <select name='category' id='category' className='form-control'>
                                {subsubcat.map((row) => (
                                  <optgroup style={{ textTransform: 'capitalize' }} label={row.id}>
                                    {row.data().Values.map((rows) => (
                                      <option value={rows + '|' + row.id + '|' + row.data().number}>{rows}</option>
                                    ))}
                                  </optgroup>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-6">
                              <TextField type='text' name='name' id='name' margin='normal' fullWidth size="small" label="Name" required />
                            </div>
                            <div className="col-lg-6">
                              {/* <TextField type='text' name='brand' id='brand' margin='normal' fullWidth size="small" label="Brand" required /> */}
                              <select className="form-control" name='brand' id='brand' style={{ marginTop: 16 }}>
                                {Gbrand.map((row) => (
                                  <option>{row}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-6">
                              <TextField type='text' name='sellercode' id='sellercode' margin='normal' fullWidth size="small" label="Seller Code" required />
                            </div>
                            <div className="col-lg-6">
                              <TextField type='number' name='tax' id='tax' margin='normal' fullWidth size="small" label="Tax in %" required />
                            </div>

                            <div className="col-lg-12">
                              <SunEditor height='200px' name='description' placeholder='Describe Product Here' />
                            </div>

                            <div className="col-lg-6">
                              <TextField type='number' name='weight' id='weight' margin='normal' fullWidth size="small" label="Weight" InputLabelProps={{ shrink: true, }} required />
                            </div>

                            <div className="col-lg-6">
                              <TextField type='text' name='hsn' id='hsn' margin='normal' fullWidth size="small" label="HSN Code" InputLabelProps={{ shrink: true, }} required />
                            </div>



                            {/* <div className="row"> */}
                            <div className="col-lg-6">
                              <label>Image</label>
                              <TextField type='file' name='image' onChange={(e) => setimage1(e.target.files[0])} id='image' margin='normal' fullWidth size="small" label="Image" InputLabelProps={{ shrink: true, }} required />
                            </div>
                            {/* <div className="col-lg-6"> */}
                            {/* </div> */}
                            {/* </div> */}

                            <div className="col-lg-6">

                              <br />
                              <label><b>Quotation</b></label>
                              <Stack direction="row" spacing={1} alignItems="center">

                                <Typography>OFF</Typography>
                                <Switch name='checked' />
                                <Typography>ON</Typography>

                              </Stack>
                            </div>
                            <div className="col-lg-3">

                              {image1 && (
                                <>
                                  <img src={URL.createObjectURL(image1)} className='img-responsive' />
                                </>
                              )}

                            </div>

                            <div className="col-lg-12">
                              {sub ? (
                                <>
                                  Uploading <CircularProgress />
                                </>
                              ) : (
                                <Button variant='contained' type="submit">Submit</Button>
                              )}
                            </div>
                          </form>



                        </div>


                        <div className="col-lg-7">
                          {/* hi */}
                        </div>



                      </div>



                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default ViewProduct;