import React, { useState } from "react";
import { Logout, Person, Dashboard, Category, ProductionQuantityLimits, LocalGroceryStore, Menu, Delete, Edit, ArrowCircleRight, Pageview, EditAttributes } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
// import TopBarAdmin from "./Header";
// import SideBarAdmin from "./Sidebar";
import boiler from './icons/boiler.png'
import firebase from "firebase";
import { db, realdb, storage } from "../Firebase";
import imageCompression from "browser-image-compression";
import { Link, useNavigate } from "react-router-dom";
import { TableContainer, Table, TableHead } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableBody } from "@mui/material";
import { Button } from "@mui/material";
import { TableRow } from "@mui/material";
import HTMLReactParser from "html-react-parser";
import Header from "./Header";
import { styled, useTheme } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function ViewProduct2() {

  const [prog, setprog] = useState('');

  const [progbar, setprogbar] = useState(false);
  const [btn, setbtn] = useState(true);

  var navi = useNavigate();

  const onSubmit = (event) => {

    setprogbar(true);
    setbtn(false);
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    var category = data.get('category');
    var image = data.get('image');
    console.log(category)
    console.log(image.size)


    db.collection("MynewCategory").where("Value", "==", data.get('category').toLocaleLowerCase()).get().then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        alert('This Category is already registered');
        setprogbar(false);
        setbtn(true);
        setprog('0%');

      } else {

        var options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        imageCompression(image, options).then(function (newimage) {
          // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true            
          // console.log(compressedFile)
          // console.log(compressedFile.size);
          var metadata = {
            contentType: 'image/jpeg'
          };

          var uploadTask = storage.ref().child('category/' + category).put(newimage, metadata);

          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setprog(Math.floor(progress) + '%');
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  break;

                // ...

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // console.log('image1 available at', downloadURL);
                db.collection("MynewCategory").add({
                  Value: data.get('category').toLocaleLowerCase(),
                  url: downloadURL,
                }).then((succ) => {
                  alert('Added');
                  setprogbar(false);
                  setbtn(true);
                  setprog('0%');
                  event.target.reset();
                }).catch((err) => {
                  alert('Error, Please Contact Admin');
                  setprogbar(false);
                  setbtn(true);
                  setprog('0%');
                })
              })
            })
        })

      }
    })
  }



  const [cat, setcat] = React.useState([]);
  const [catname, setcatname] = React.useState([]);
  const getCat = () => {
    db.collection("All-Sub-Sub-Category").onSnapshot((querySnapshot) => {
      var allcat = [];
      var allcatname = [];
      querySnapshot.forEach((docs) => {
        allcatname.push(docs.id);
      })
      setcat(allcat);
      setcatname(allcatname)
    });
  }

  // console.log(catname);

  const catDel = (x) => {
    alert(x.id);
    db.collection("MynewCategory").doc(x.id).delete();
    storage.refFromURL(x.data().url).delete();
  }

  var id = new URLSearchParams(window.location.search).get('id');


  const [pro, setpro] = React.useState([]);
  const [limit, setlimit] = React.useState(5);


  function getProducts(x) {
    // console.log(limit);
    // if(limit)
    var limits = parseInt(limit)
    var query = db.collection("All-AllProducts")

    if (id) {
      console.log('yes');
      var query = db.collection("All-AllProducts").where('Category', '==', id)
    }

    if (x) {
      console.log(x);
      var ar = x.split(' ');
      console.log(ar);
      query = query.where('Keywords', 'array-contains-any', ar)
    }

    query.limit(limits).orderBy('pid', 'desc').onSnapshot((suc) => {
      var ar = []
      suc.forEach((succ) => {
        ar.push(succ);
      })
      setpro(ar);
    })
  }






  function delPro(x) {
    let text = "Press Ok to Confirm.";
    if (window.confirm(text) == true) {

      for (var i = 0; i < x.data().images.length; i++) {
        storage.refFromURL(x.data().images[i]).delete();
      }
      db.collection("All-AllProducts").doc(x.id).delete();
    } else {
      //
    }
  }

  function viewPro(x) {
    var path = '/View?id=' + x;
    navi(path);
  }
  function viewPro2(x) {
    var path = '/Edit?id=' + x;
    navi(path);
  }

  React.useEffect(() => {
    getCat();
  }, [])

  React.useEffect(() => {
    getProducts();
  }, [id, limit])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />

          {/* <TopBarAdmin/> */}
          {/* <Divider/> */}
          <div className="row">
            {/* <SideBarAdmin/> */}
            <div className="col-lg-12">
              <br />
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-left">
                  <li className="page-item"><Link className="page-link" to="/AdminProduct">Add Product</Link></li>
                  <li className="page-item active"><Link className="page-link" to="/AdminProduct/View">View Product</Link></li>
                </ul>
              </nav>
              <div className="row">

                <div className="col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-lg-2">
                          <select className="form-control" onChange={(e) => setlimit(e.target.value)}>
                            <option value={5}>Limit 5</option>
                            <option value={10}>Limit 10</option>
                            <option value={50}>Limit 50</option>
                            <option value={100}>Limit 100</option>
                          </select>
                        </div>
                        <div className="col-lg-7">
                          <ul className="pagination">
                            <li className={"page-item " + (!id && (' active'))}><Link className="page-link" to={'/AdminProduct/View'}>All Products</Link></li>
                            {catname.map((row) => (
                              <li key={row} className={"page-item " + (row == id && (' active'))}>
                                <Link className="page-link" to={'/AdminProduct/View?id=' + row}>{row}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="col-lg-3">
                          <input type='text' className="form-control" placeholder="Search Product" onKeyUp={(e) => getProducts(e.target.value)} />
                        </div>
                      </div>



                    </div>
                    <div className="card-body">

                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>PID</TableCell>
                              <TableCell>Seller Code</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Brand</TableCell>
                              <TableCell>Category</TableCell>
                              <TableCell>Quatation</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pro.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell>{row.data().pid}</TableCell>
                                <TableCell>{row.data().sellercode}</TableCell>
                                <TableCell>{row.data().Name.slice(0, 50)}....</TableCell>
                                <TableCell>{row.data().Brand}</TableCell>
                                <TableCell>{row.data().SubCategory}</TableCell>
                                <TableCell>{row.data().addtocart.toString()}</TableCell>
                                <TableCell>

                                  <Button onClick={() => delPro(row)} size="small" variant='contained' color='warning'><Delete /></Button>
                                  <Button onClick={() => viewPro(row.id)} size="small" variant='contained' color='success'><Pageview /></Button>
                                  <Button onClick={() => viewPro2(row.id)} size="small" variant='contained' color='info'><Edit /></Button>

                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>







                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default ViewProduct2;