import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { auth, db } from "./Firebase";
import firebase from "firebase";
import { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { Snackbar } from "@mui/material";

export default function ProductDetail() {

    var catid = new URLSearchParams(useLocation().search).get("p");

    const [products, setproducts] = useState([]);
    const [productsstock, setproductsstock] = useState([]);
    const [productsstockqty, setproductsstockqty] = useState(0);
    const [productselected, setproductselected] = useState();
    const [productselectedsqty, setproductselectedsqty] = useState(1);

    useEffect(() => {
        if (productselected) {
            setproductsstockqty(productselected.data().Stock);
        }
    }, [productselected])

    function getproducts() {
        if (catid) {
            var qr = db.collection("All-AllProducts").doc(catid)
            qr.get().then((succ) => {
                if (succ.exists) {
                    qr.collection("Stock").orderBy("Size", "asc").get().then((succc) => {
                        setproducts([succ]);
                        setproductsstock(succc.docs);
                    })
                }
                // console.log(succ.data());
            })
        }
    }

    useEffect(() => {
        getproducts();
    }, [catid])


    const [user, setuser] = useState('');

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setuser(user)
            } else {

            }
        });
    }, [])

    // console.log(user);

    const [open, setopen] = useState(false);
    const [openmessage, setopenmessage] = useState("");

    function addtocart() {
        if (user) {
            // alert('Ok');

            // user.phoneNumber
            // db.collection("Users").doc(user.uid).get().then((succ) => {

            var pro_obj = productselected.data();

            Object.assign(pro_obj, {
                pid: productselected.id,
                Bought: productselectedsqty,
            })

            var obj = Object.assign(products[0].data(), {
                ProductId: products[0].id,
                Product: pro_obj,
            })
            // console.log(productselected.data());
            console.log(obj);

            var qr = db.collection("Users").doc(user.uid).collection("Cart").doc(productselected.id)

            qr.get().then((chk) => {
                if (chk.exists) {
                    // alert("Already in Cart");
                    setopenmessage("Already in Cart");
                    checkcrt();
                    setopen(true);
                } else {
                    qr.set(obj).then((succ) => {
                        setopenmessage("Added to Cart");
                        setopen(true);
                        checkcrt();
                    })
                }

            })

            // })

        } else {
            // alert("Please Login First to Login");
            setopenmessage("Please Login First");
            setopen(true);
        }
    }


    const [isfalse, setisfalse] = useState(false);

    // console.log(productselected);

    function checkcrt() {
        if (productselected) {
            var qr = db.collection("Users").doc(user.uid).collection("Cart").doc(productselected.id)
            qr.get().then((chk) => {
                if (chk.exists) {
                    setisfalse(true);
                } else {
                    setisfalse(false);
                }
            })
        }
    }
    useEffect(() => {
        checkcrt();
    }, [productselected])

    // console.log(isfalse);

    function removetocart() {
        if (productselected) {
            var qr = db.collection("Users").doc(user.uid).collection("Cart").doc(productselected.id)
            qr.delete().then((succ) => {
                alert("Removed from cart");
                checkcrt();
            })
        }

    }



    return (
        <>

            <Navbar />

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setopen(false)}
                message={openmessage}
            />
            {/* <!-- Shop Detail Start --> */}
            {products.length <= 0 ? (
                <>
                    <div className="alert alert-warning text-center">No Product Selected</div>
                </>
            ) : (<>
                {products.map((row) => (
                    <div class="container-fluid pb-5">
                        <div class="row px-xl-5">
                            <div class="col-lg-5 mb-30">
                                <div id="product-carousel" class="carousel slide" data-ride="carousel">
                                    <div class="carousel-inner bg-light">
                                        {row.data().images.map((img) => (
                                            <div class="carousel-item active">
                                                <img class="w-100 h-100" src={img} alt="Image" />
                                            </div>
                                        ))}
                                        {/* <div class="carousel-item">
                                        <img class="w-100 h-100" src="img/product-2.jpg" alt="Image" />
                                    </div>
                                    <div class="carousel-item">
                                        <img class="w-100 h-100" src="img/product-3.jpg" alt="Image" />
                                    </div>
                                    <div class="carousel-item">
                                        <img class="w-100 h-100" src="img/product-4.jpg" alt="Image" />
                                    </div> */}
                                    </div>
                                    <a class="carousel-control-prev" href="#product-carousel" data-slide="prev">
                                        <i class="fa fa-2x fa-angle-left text-dark"></i>
                                    </a>
                                    <a class="carousel-control-next" href="#product-carousel" data-slide="next">
                                        <i class="fa fa-2x fa-angle-right text-dark"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-7 h-auto mb-30">
                                {isfalse.toString()}
                                <div class="h-100 bg-light p-30">
                                    <h3 className="text-capitalize">{row.data().Name}</h3>
                                    <div class="d-flex mb-3">
                                        <div class="text-primary mr-2">
                                            <small class="fas fa-star"></small>
                                            <small class="fas fa-star"></small>
                                            <small class="fas fa-star"></small>
                                            <small class="fas fa-star-half-alt"></small>
                                            <small class="far fa-star"></small>
                                        </div>
                                        {/* <small class="pt-1">(99 Reviews)</small> */}
                                    </div>
                                    <h3 class="font-weight-semi-bold mb-4">

                                        {/* {console.log(row.data().Avg_price.sort(function (a, b) { return a - b }))} */}
                                        {/* {row.data().Avg_price.sort().map((rowwww) =>
                                        rowwww + "<br/>"
                                    )} */}
                                        <br />
                                        ₹{(row.data().Avg_price.sort(function (a, b) { return a - b }))[0]} - ₹{(row.data().Avg_price.sort(function (a, b) { return a - b }))[row.data().Avg_price.length - 1]}</h3>

                                    {/* <p class="mb-4">Volup erat ipsum diam elitr rebum et dolor. Est nonumy elitr erat diam stet sit clita ea. Sanc ipsum et, labore clita lorem magna duo dolor no sea Nonumy</p> */}
                                    <div class="mb-3">
                                        <strong class="text-dark mr-3">Choose a Sizes</strong>
                                        <br />
                                        <form className="row m-0">
                                            {productsstock.map((rows) => (
                                                <div className="bg-ok col-lg-3 col-md-3 col-sm-4 col-4 text-center">
                                                    <div class="custom-control custom-radio">
                                                        <input onClick={() => setproductselected(rows)} type="radio" class="custom-control-input" id={rows.id} name="size" />
                                                        <label class="custom-control-label" for={rows.id}>
                                                            <img src={rows.data().Image[0]} className="img-fluid" />
                                                            <br />
                                                            {rows.data().Material}
                                                            <br />
                                                            {rows.data().Size}
                                                            <br />
                                                            ₹{Number(rows.data().Selling).toFixed(2)} <del>₹{Number(rows.data().MRP).toFixed(2)}</del>
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </form>
                                    </div>
                                    {row.data().addtocart ? (
                                        <div className="alert alert-warning">
                                            Get Quatation
                                        </div>
                                    ) : (
                                        <>
                                            {productselected ? (<>
                                                {productsstockqty <= 0 ? (<>
                                                    <div className="alert alert-warning">Out of Stock</div>
                                                </>) : (<>
                                                    <div className="h5">
                                                        Quantity<sup className="badge">InStock: {productsstockqty}</sup>:
                                                    </div>
                                                    <div class="d-flex align-items-center mb-4 pt-2">

                                                        {isfalse ? (
                                                            <button onClick={removetocart} class="btn btn-danger rounded px-5 py-2"><i class="fa fa-shopping-cart mr-1"></i> Remove From Cart</button>
                                                        ) : (
                                                            <>
                                                                <div class="btn-group mr-3">
                                                                    <button onClick={() => setproductselectedsqty(productselectedsqty - 1)} disabled={productselectedsqty <= 1 && true} class="btn btn-primary rounded">
                                                                        <i class="fa fa-minus"></i>
                                                                    </button>
                                                                    <button disabled className="btn btn-secondary">
                                                                        {productsstockqty <= 0 ? 0 : productselectedsqty}
                                                                    </button>
                                                                    {/* <input type="number" min={productsstockqty <= 0 ? 0 : 1} max={productsstockqty} class="form-control bg-secondary border-0 text-center p2" /> */}
                                                                    <button onClick={() => setproductselectedsqty(productselectedsqty + 1)} disabled={productselectedsqty >= productsstockqty && true} class="btn btn-primary rounded">
                                                                        <i class="fa fa-plus"></i>
                                                                    </button>
                                                                </div>

                                                                <button onClick={addtocart} class="btn btn-primary rounded px-5 py-2"><i class="fa fa-shopping-cart mr-1"></i> Add To Cart</button>
                                                            </>
                                                        )}
                                                    </div>
                                                </>)}
                                            </>) : (<>
                                                <div className="alert alert-warning">Select Variant</div>
                                            </>)}
                                        </>
                                    )}
                                    {/* <div class="d-flex pt-2">
                                    <strong class="text-dark mr-2">Share on:</strong>
                                    <div class="d-inline-flex">
                                        <a class="text-dark px-2" href="">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                        <a class="text-dark px-2" href="">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                        <a class="text-dark px-2" href="">
                                            <i class="fab fa-linkedin-in"></i>
                                        </a>
                                        <a class="text-dark px-2" href="">
                                            <i class="fab fa-pinterest"></i>
                                        </a>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="row px-xl-5">
                            <div class="col">
                                <div class="bg-light p-30">
                                    <div class="nav nav-tabs mb-4">
                                        <a class="nav-item nav-link text-dark active h1" data-toggle="tab" href="#tab-pane-1">Description</a>
                                    </div>
                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="tab-pane-1">
                                            <h4 class="mb-3">Product Description</h4>
                                            {HTMLReactParser(row.data().Description)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </>)}
            {/* <!-- Shop Detail End --> */}


            <Footer />

            {/* <!-- Back to Top --> */}
            <a href="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></a>


        </>
    )
}