import { Box, Button } from "@mui/material";
import Header from "./Header";
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { db, storage } from "../Firebase";
import imageCompression from "browser-image-compression";
import firebase from "firebase";
import { ArrowCircleRight, Delete, Edit } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


export default function Category() {

    // categroy start
    const [prog, setprog] = useState('');

    const [progbar, setprogbar] = useState(false);
    // const [btn, setbtn] = useState(true);

    const onSubmit = (event) => {

        setprogbar(true);
        // setbtn(false);
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        var category = data.get('category');
        var image = data.get('image');
        console.log(category)
        console.log(image.size)

        var num = 1;

        db.collection("Category").orderBy('number', 'desc').limit(1).get().then(function (suc) {
            if (suc.size == 0) {
                num = 1;
            }
            suc.forEach(function (suc) {
                num = suc.data().number + 1;
            })

            console.log(num);


            db.collection("Category").where("Value", "==", data.get('category').toLocaleLowerCase()).get().then((querySnapshot) => {
                if (querySnapshot.size > 0) {
                    alert('This Category is already registered');
                    setprogbar(false);
                    // setbtn(true);
                    setprog('0%');

                } else {

                    var options = {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    }
                    imageCompression(image, options).then(function (newimage) {
                        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true            
                        // console.log(compressedFile)
                        // console.log(compressedFile.size);
                        var metadata = {
                            contentType: 'image/jpeg'
                        };

                        var uploadTask = storage.ref().child('Category/' + category).put(newimage, metadata);

                        // Listen for state changes, errors, and completion of the upload.
                        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                            (snapshot) => {
                                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                setprog(Math.floor(progress) + '%');
                                console.log('Upload is ' + progress + '% done');
                                switch (snapshot.state) {
                                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                                        console.log('Upload is paused');
                                        break;
                                    case firebase.storage.TaskState.RUNNING: // or 'running'
                                        console.log('Upload is running');
                                        break;
                                }
                            },
                            (error) => {
                                // A full list of error codes is available at
                                // https://firebase.google.com/docs/storage/web/handle-errors
                                switch (error.code) {
                                    case 'storage/unauthorized':
                                        // User doesn't have permission to access the object
                                        break;
                                    case 'storage/canceled':
                                        // User canceled the upload
                                        break;

                                    // ...

                                    case 'storage/unknown':
                                        // Unknown error occurred, inspect error.serverResponse
                                        break;
                                }
                            },
                            () => {
                                // Upload completed successfully, now we can get the download URL
                                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                    // console.log('image1 available at', downloadURL);
                                    db.collection("Category").add({
                                        Value: data.get('category').toLocaleLowerCase(),
                                        url: downloadURL,
                                        number: num
                                    }).then((succ) => {
                                        alert('Added');
                                        setprogbar(false);
                                        // setbtn(true);
                                        setprog('0%');
                                        event.target.reset();
                                    }).catch((err) => {
                                        alert('Error, Please Contact Admin');
                                        setprogbar(false);
                                        // setbtn(true);
                                        setprog('0%');
                                    })
                                })
                            })
                    })

                }
            })
        })
    }


    const [cat, setcat] = useState([]);
    const getCat = () => {
        // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
        db.collection("Category").onSnapshot((querySnapshot) => {
            var allcat = [];
            querySnapshot.forEach((docs) => {
                allcat.push(docs);
            })
            setcat(allcat);
        });
    }


    const catDel = (x) => {
        var txt = 'Do want to delete the category';
        if (window.confirm(txt)) {
            alert(x.data().Value + ' Deleted');
            db.collection("Category").doc(x.id).delete();
            storage.refFromURL(x.data().url).delete();
        } else {

        }
    }


    useEffect(() => {
        getCat();
    }, [])

    // category ends

    var catid = new URLSearchParams(useLocation().search).get('id');

    console.log(catid);



    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Header />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    {catid ? (
                        <div className="row m-0 p-0">
                            <div className="col-12">
                                <h4>Sub Category</h4>
                            </div>
                            <div className="col-lg-3">
                                <div className="card">
                                    <div className="card-body">

                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row m-0 p-0">
                            <div className="col-12">
                                <h4>Category</h4>
                            </div>
                            <div className="col-lg-3">
                                <div className="card mb-2">
                                    <div className="card-body">
                                        <form onSubmit={onSubmit}>
                                            <input type="file" className="form-control my-2" name="image" autoFocus required />
                                            <input type="text" className="form-control my-2" name="category" placeholder="Title" required />
                                            {progbar ? (
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: prog }}>
                                                        {prog}
                                                    </div>
                                                </div>
                                            ) : (
                                                <Button type="submit" variant="contained">Add Category</Button>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="row">
                                    {cat.map((row) => (
                                        <div className="col-lg-2 col-6" style={{ marginBottom: 5 }} key={row.id}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <button className='close' onClick={() => catDel(row)} style={{ position: 'absolute', top: 5, left: 5 }}><Delete /></button>
                                                    <button data-toggle="modal" data-target={"#myModal" + row.id} className='close' style={{ position: 'absolute', right: 5, top: 5 }}><Edit /></button>
                                                    <div className="col">
                                                        <img src={row.data().url} className='img-fluid' />
                                                    </div>
                                                </div>
                                                <div className="card-footer text-center">
                                                    {row.data().Value}
                                                    <Link className="btn" to={'/SubCategory?id=' + row.data().Value}><ArrowCircleRight className="close" /></Link>
                                                </div>
                                            </div>
                                            <div className="modal mt-5" id={"myModal" + row.id}>
                                                <div className="modal-dialog modal-md">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">{row.data().Value}</h4>
                                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="row">
                                                                <div className="col-md-5">
                                                                    <img src={row.data().url} className='img-fluid' />
                                                                    <div className="form-group">
                                                                        <input type='file' className='form-control' />
                                                                    </div>
                                                                    <div>
                                                                        <button type="button" className="btn btn-success">Update Image</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <div className="form-group">
                                                                        <input type='text' className='form-control' required />
                                                                    </div>
                                                                    <div>
                                                                        <button type="button" className="btn btn-success">Update</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </Box>
            </Box>
        </>
    )
}