import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { auth, db } from "./Firebase";
import firebase from "firebase";
import { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { Button, Snackbar, TextField } from "@mui/material";
import { AccountCircle, Delete, Home } from "@mui/icons-material";

export default function Profile() {

    const [user, setuser] = useState('');

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setuser(user)
            } else {

            }
        });
    }, [])

    const [open, setopen] = useState(false);
    const [openmessage, setopenmessage] = useState("");


    function addaddressdata(e) {
        e.preventDefault();
        var data = new FormData(e.currentTarget);
        var name = data.get("name");
        var email = data.get("email");
        var contact = data.get("contact");
        var pincode = data.get("pincode");
        var state = data.get("state");
        var city = data.get("city");
        var contact2 = data.get("contact2");
        var address = data.get("address");
        db.collection("Users").doc(user.uid).collection("Address").add({
            name: name,
            email: email,
            contact: contact,
            pincode: pincode,
            state: state,
            city: city,
            contact2: contact2,
            address: address,
        }).then((succ) => {
            e.target.reset();
            // alert("Address Added");
            setopen(true);
            setopenmessage("Address Added")
        })

    }


    const [addr, setaddr] = useState([]);
    function getaddress() {
        if (user) {
            db.collection("Users").doc(user.uid).collection("Address").onSnapshot((succ) => {
                setaddr(succ.docs);
            })
        }
    }
    useEffect(() => {
        getaddress();
    }, [user])

    function dell(x) {
        db.collection("Users").doc(user.uid).collection("Address").doc(x).delete().then((succ) => {
            setopen(true);
            setopenmessage("Address Deleted");
        })
    }


    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setopen(false)}
                message={openmessage}
            />

            <Navbar />

            <div className="container-fluid">
                <div className="row m-0">
                    <div className="col-lg-2">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <AccountCircle /> Profile
                            </li>
                            <li className="list-group-item">
                                <Home /> Address
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-10">
                        <div className="card">
                            <div className="card-body">
                                <div className="row m-0">
                                    <div className="col-lg-3">
                                        <p>Add New Address</p>
                                        <form onSubmit={addaddressdata}>
                                            <TextField type="text" name="name" label="Name" required fullWidth margin="dense" />
                                            <TextField type="email" name="email" label="Email" required fullWidth margin="dense" />
                                            <TextField type="number" name="contact" label="Contact" required fullWidth margin="dense" />
                                            <TextField type="number" name="pincode" label="Pincode" required fullWidth margin="dense" />
                                            <TextField type="text" name="state" label="State" required fullWidth margin="dense" />
                                            <TextField type="text" name="city" label="City/District/Town" required fullWidth margin="dense" />
                                            <TextField type="number" name="contact2" label="Alternate Phone (Opitonal)" fullWidth margin="dense" />
                                            <TextField type="text" rows={4} name="address" label="Full Address" multiline fullWidth margin="dense" />
                                            <Button type="submit" variant="contained">Save</Button>
                                        </form>
                                    </div>
                                    <div className="col-lg-9">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Contact</th>
                                                    <th>Pincode</th>
                                                    <th>Address</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {addr.map((row) => (
                                                    <tr>
                                                        <td>{row.data().name}</td>
                                                        <td>{row.data().email}</td>
                                                        <td>{row.data().contact}</td>
                                                        <td>{row.data().pincode}</td>
                                                        <td>{row.data().address} ,{row.data().pincode}, {row.data().city}, {row.data().state}</td>
                                                        <td>
                                                            <Button onClick={() => dell(row.id)}><Delete /></Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            {/* <!-- Back to Top --> */}
            <a href="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></a>


        </>
    )
}