import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { auth, db } from "./Firebase";
import firebase from "firebase";
import { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { Button, Fab, Radio, RadioGroup, Snackbar, Step, StepLabel, Stepper } from "@mui/material";
import { Home, Payment, ShoppingBag } from "@mui/icons-material";

export default function Success() {

    const [user, setuser] = useState('');

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setuser(user)
            } else {

            }
        });
    }, [])





    return (
        <>

            <Navbar />

            <div className="row m-0 justify-content-center">
                <div class="col-lg-3">
                    <div className="card">
                        <div className="card-body text-center">
                            <div style={{ borderRadius: 200, height: 200, width: 200, background: '#e2f0ce', margin: 'auto' }} className="d-flex justify-content-center">
                                <i class="checkmark">✓</i>
                            </div>
                            <br />
                            <h1>Success</h1>
                            <p>We received your purchase request;<br /> we'll be in touch shortly!</p>
                            <div className="btn-group">
                                <Link className="btn btn-info" to='/'>Continue Shopping</Link>
                                <Link className="btn btn-success" to='/Orders'>View Orders</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            {/* <!-- Back to Top --> */}
            <a href="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></a>


        </>
    )
}