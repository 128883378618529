import { Button, MenuItem, Select, TextField } from "@mui/material";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { auth, db } from "./Firebase";
import firebase from "firebase";
import { Country, State, City } from 'country-state-city';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Login() {


    const [ccode, setccode] = useState(Country.getAllCountries());
    // phonecode
    // useEffect(() => {
    // console.log(Country.getAllCountries());
    // }, [])

    const [checkconfirmation, setcheckconfirmation] = useState(false);
    const [confirmation, setconfirmation] = useState();


    function SendOTP(e) {
        e.preventDefault();
        var data = new FormData(e.currentTarget);
        var code = data.get("code");
        var contact = data.get("contact");
        // console.log(isNaN(contact));
        if (contact.length == 10 && !isNaN(contact)) {

            // db.collection("Users").where("Contact", "==", contact).get().then((succ) => {
            // var sss = false;
            // if (succ.size <= 0) {
            //     db.collection("Users").add({
            //         Contact: contact
            //     }).then((succ) => {
            //         sss = true;
            //     })
            // } else {
            //     sss = true;
            // }
            // if (sss) {
            const phoneNumber = "+" + code + contact;
            const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    alert("OTP Sent");
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    setconfirmation(confirmationResult);
                    setcheckconfirmation(true);
                    // ...
                }).catch((error) => {
                    // Error; SMS not sent
                    // ...
                    alert(error.message);
                    window.location.replace("/");
                });
            // }
            // })

        } else {
            alert('Please fill Correct Number');
        }
    }


    function submitOTP(e) {
        e.preventDefault();
        var data = new FormData(e.currentTarget);
        var code = data.get("otp");
        console.log(code);
        confirmation.confirm(code).then((result) => {
            // User signed in successfully.
            const user = result.user;
            // ...
            // alert('Ok');

            // console.log(user)

            var qr = db.collection("Users").doc(user.uid);

            qr.get().then((Succ) => {
                if (!Succ.exists) {
                    qr.set({
                        Contact: user.phoneNumber,
                        id: user.uid
                    }).then((fsuc) => {
                        window.location.replace("/");
                    })
                } else {
                    window.location.replace("/");
                }
            })

            // window.location.replace("/");
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
            console.log(error);
            alert(error.message)
        });
    }



    const [user, setuser] = useState('');

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setuser(user)
            }
        });
    }, [])

    console.log(user);


    return (
        <>

            <Navbar />

            <div class="container-fluid mb-3">
                <div class="row px-xl-5 justify-content-center">
                    <div className="col-lg-7">
                        <div className="card rounded shadow-lg">
                            <div className="">
                                {user ? (
                                    <>
                                        <div className="text-center p-2">
                                            <div className="alert alert-success text-center">
                                                Already Loged In
                                            </div>
                                            <Link className="btn btn-success rounded" to="/">Continue Shopping</Link>
                                        </div>
                                        {/* {user.} */}
                                    </>
                                ) : (
                                    <div className="row m-0">
                                        <div className="col-lg-4 py-lg-5 pt-4 rounded-start bg-primary h50">
                                            <h1 className="text-light h3 font-weight-bolder">Login</h1>
                                            <p className="text-light ">
                                                Get access to your Orders,<br />Wishlist and Recommendations</p>

                                        </div>
                                        <div className="col-lg-8 py-5 rounded-end bg-light">
                                            {!checkconfirmation ? (
                                                <form onSubmit={SendOTP}>
                                                    <div className="row m-0">
                                                        <div className="col-lg-4 p-1">
                                                            <TextField defaultValue={91} name="code" select fullWidth>
                                                                {ccode.map((row) => (
                                                                    <MenuItem value={row.phonecode}>
                                                                        {row.name} ({row.phonecode})
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </div>
                                                        <div className="col-lg-8 p-1">
                                                            <TextField type="number" name="contact" label="Enter your Mobile Number" fullWidth required />
                                                        </div>
                                                        <div className="col-lg-12 p-1">
                                                            <Button type="submit" className="mt-2" variant="contained" fullWidth color="warning">Request OTP</Button>
                                                            <div id="recaptcha-container"></div>
                                                        </div>
                                                    </div>
                                                </form>
                                            ) : (
                                                <form onSubmit={submitOTP}>
                                                    <TextField type="number" name="otp" label="Enter OTP" required fullWidth />
                                                    <Button type="submit" className="my-3" variant="contained" fullWidth color="warning">Submit OTP</Button>
                                                    OTP not received Click <a href="/Login">Here</a>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div >
                </div >
            </div >

            <Footer />

            {/* <!-- Back to Top --> */}
            <a href="#" class="btn btn-primary back-to-top"><i class="fa fa-angle-double-up"></i></a>


        </>
    )
}