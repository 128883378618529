import React, { useEffect, useRef, useState } from "react";
import { Logout, Person, Dashboard, Category, ProductionQuantityLimits, LocalGroceryStore, Menu, Delete, Edit, ArrowCircleRight, Image, Camera } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
// import TopBarAdmin from "./Header";
// import SideBarAdmin from "./Sidebar";
import boiler from './icons/boiler.png'
import firebase from "firebase";
import { db, storage } from "../Firebase";
import imageCompression from "browser-image-compression";
import { Link, useNavigate } from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import HTMLReactParser from "html-react-parser";
import Header from "./Header";
import { styled, useTheme } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


function ViewOneProduct2() {


  const [prog, setprog] = useState('');

  const [progbar, setprogbar] = useState(false);
  const [btn, setbtn] = useState(true);

  const navigate = useNavigate();

  const [cat, setcat] = React.useState([]);
  const getCat = () => {
    // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
    db.collection("MynewCategory").onSnapshot((querySnapshot) => {
      var allcat = [];
      querySnapshot.forEach((docs) => {
        allcat.push(docs);
      })
      setcat(allcat);
    });
  }
  var id = new URLSearchParams(window.location.search).get('id');

  const [sub, setsub] = React.useState(false);

  const onSubmit = (event) => {
    setsub(true);
    event.preventDefault();
    setsub(false);
  }


  const [data, setdata] = useState();

  const [name, setname] = useState('');
  const [brand, setbrand] = useState('');
  const [cate, setcate] = useState('')
  const [subcat, setsubcat] = useState('')
  const [weight, setweight] = useState('')
  const [hsn, sethsn] = useState('')
  const [sellercode, setsellercode] = useState('')
  const [tax, settax] = useState('')
  const [description, setdescription] = useState('');
  const [allimages, setimages] = useState([]);
  const [alladdto, setaddto] = useState([]);


  function findProduct() {
    db.collection('All-AllProducts').doc(id).onSnapshot(function (suc) {
      setname(suc.data().Name);
      setbrand(suc.data().Brand);
      setcate(suc.data().Category);
      setsubcat(suc.data().SubCategory);
      setweight(suc.data().Weight);
      sethsn(suc.data().hsn);
      setsellercode(suc.data().sellercode);
      settax(suc.data().tax);
      setdescription(suc.data().Description);
      setimages(suc.data().images);
      setaddto(suc.data().addtocart);
    })
  }


  useEffect(() => {
    findProduct();
  }, [id])

  const [subsubcat, setsubsubcat] = React.useState([]);
  const [subsubcatlist, setsubsubcatlist] = React.useState([]);
  const getsubsubCat = () => {
    // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
    db.collection("Sub-Sub-Category").onSnapshot((querySnapshot) => {
      var allsubsubcat = [];
      var allsubsubcatlist = [];
      querySnapshot.forEach((docs) => {
        allsubsubcat.push(docs);
        // console.log(docs.data().Values);
        allsubsubcatlist.push(docs.data().Values);
        // console.log(allsubsubcatlist);
      })
      setsubsubcat(allsubsubcat);
      setsubsubcatlist(allsubsubcatlist);
    });
  }


  const [allstock, setallstock] = React.useState([]);

  const getStock = () => {
    db.collection('All-AllProducts').doc(id).collection('Stock').onSnapshot(function (succ) {
      var ar = [];
      succ.forEach(function (succc) {
        ar.push(succc);
      })
      setallstock(ar);
    })
  }


  const inputFileRef = useRef(null);

  function opencamera() {
    inputFileRef.current.click();
  }

  const [nimg, setnimg] = useState();

  function setnewimg(x) {
    console.log(x);
    setnimg(x);
  }

  function setdel(x) {
    let text = "Press Ok to Confirm.";
    if (window.confirm(text) == true) {

      // alert(x);
      var index = allimages.indexOf(x);
      if (index > -1) {
        allimages.splice(index, 1)
      }
      // console.log(allimages);
      db.collection('All-AllProducts').doc(id).update({
        images: allimages
      }).then(function (succ) {
        storage.refFromURL(x).delete();
      })
    } else {
      // 
    }
  }

  function addimage() {
    // console.log(nimg);
    // allimages.push(nimg);
    // console.log(allimages);


    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1000,
      useWebWorker: true
    }

    var metadata = {
      contentType: 'image/jpeg'
    };

    // console.log(category.split("|"));

    var random = Math.floor(Math.random() * (1000000000 - 100000000)) + 100000000;


    var uploadTask = storage.ref().child('images/' + random + '/' + nimg.name).put(nimg, metadata);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        switch (error.code) {
          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('image1 available at', downloadURL);

          // console.log(nimg);
          allimages.push(downloadURL);
          console.log(allimages);
          db.collection('All-AllProducts').doc(id).update({
            images: allimages
          }).then(function (succ) {
            setnimg(null)
          })
        })
      })
  }


  const onSubmit2 = (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    var name = data.get('name').toLowerCase();
    var brand = data.get('brand').toLowerCase();
    var sellercode = data.get('sellercode');
    var weight = data.get('weight');
    var hsn = data.get('hsn');
    var tax = data.get('tax');
    var description = data.get('description');

    db.collection('All-AllProducts').doc(id).update({
      Name: name,
      Brand: brand,
      sellercode: sellercode,
      Weight: weight,
      hsn: hsn,
      tax: tax,
      Description: description,
    }).then(function (succ) {
      alert('Product Updated');
    })


  }





  React.useEffect(() => {
    getCat();
    getsubsubCat()
    getStock()
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {/* <TopBarAdmin/> */}
          {/* <Divider/> */}
          <div className="row">
            {/* <SideBarAdmin/> */}
            <div className="col-lg-12">
              <br />
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-left">
                  <li class="page-item"><Link class="page-link" to="/AdminProduct">Add New Product</Link></li>
                  <li class="page-item"><Link class="page-link" to="/AdminProduct/View">View Product</Link></li>
                </ul>
              </nav>

              <div className="row">

                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <ul class="pagination justify-content-center">
                        <li class="page-item active"><Link class="page-link" to={'/Edit?id=' + id}>Edit Product</Link></li>
                        <li class="page-item"><Link class="page-link" to={'/View?id=' + id}>Add Stock</Link></li>
                        <li class="page-item"><Link class="page-link" to={'/Keyword?id=' + id}>Manage Keywords</Link></li>
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="col">
                        <h6>Update Product</h6>
                      </div>
                      <div className="row">

                        <div className="col-lg-4">
                          <form onSubmit={onSubmit2}>

                            <TextField type='text' name='name' id='name' margin='normal' fullWidth size="small" label="Name" required value={name} onChange={(e) => setname(e.target.value)} />

                            <TextField type='text' name='brand' id='brand' margin='normal' fullWidth size="small" label="Brand" required value={brand} onChange={(e) => setbrand(e.target.value)} />

                            <TextField type='text' name='sellercode' id='sellercode' margin='normal' fullWidth size="small" label="sellercode" required value={sellercode} onChange={(e) => setsellercode(e.target.value)} />

                            <TextField type='text' name='weight' id='weight' margin='normal' fullWidth size="small" label="weight" required value={weight} onChange={(e) => setweight(e.target.value)} />

                            <TextField type='text' name='hsn' id='hsn' margin='normal' fullWidth size="small" label="hsn" required value={hsn} onChange={(e) => sethsn(e.target.value)} />

                            <TextField type='text' name='tax' id='tax' margin='normal' fullWidth size="small" label="tax" required value={tax} onChange={(e) => settax(e.target.value)} />

                            {/* {HTMLReactParser(description)} */}
                            <SunEditor name="description" setContents={description} height='300px' />
                            <br />

                            <Button type="submit" variant="contained">Update</Button>
                          </form>
                        </div>

                        <div className="col-lg-8">
                          <div className="row">
                            <div className="card col-lg-3">
                              <div className="card-body">
                                <label><b>Choose File</b></label><br />
                                <input type='file' onChange={(e) => setnewimg(e.target.files[0])} className="form-control" ref={inputFileRef} id="image" style={{ display: 'none' }} />
                                <button className="btn btn-danger" onClick={opencamera}><Camera /></button>
                                &nbsp;&nbsp;
                                {nimg && (<>
                                  <button onClick={addimage} className="btn btn-success">upload</button>
                                  <br /><br />
                                  <img src={URL.createObjectURL(nimg)} className='img-fluid' />
                                </>)}
                              </div>
                            </div>
                            <div className="col-lg-9">
                              <div className="row">
                                {allimages.map((row) => (
                                  <div className="col-lg-4">
                                    <div className="card">
                                      <div className="card-body" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={row} className='img-fluid' />
                                      </div>
                                      <div className="card-footer">
                                        <Button onClick={() => setdel(row)}><Delete /></Button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>


                            </div>
                          </div>
                          <br />
                        </div>
                      </div>



                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default ViewOneProduct2;