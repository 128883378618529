import React from "react";
import { useNavigate } from "react-router-dom";
import './admin.css';
import firebase from "firebase";
import { db } from './../Firebase';


function AdminLogin() {

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //   alert(data.get('password'));
    db.collection("Admin_Login").where("Email", "==", data.get('email')).where("Password", "==", data.get('password')).get().then((qq) => {
      if (qq.size == 0) {
        alert('Wrong Id or Password');
      } else {
        localStorage.setItem('AdminLogin', 'ADMIN@8968555');
        navigate('/AdminDashboard');
      }
    })
  };



  return (
    <>
      <br /><br />
      <div class="wrapper">
        <div id="formContent">

          <div class=" first">
            <br />
            <img src="images/logo.png" alt="" id="icon" style={{ maxHeight: 65, maxWidth: 50 }} />
            <br />
          </div>

          <form onSubmit={handleSubmit}>
            <input type="email" class="form-control-ui  second" name="email" autoFocus placeholder="Email" />
            <input type="password" class="form-control-ui  third" name="password" placeholder="password" />
            <input type="submit" class="form-control-btn  fourth" value="Log In" />
          </form>

        </div>
      </div>
    </>
  )
}

export default AdminLogin;