import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { auth, db } from "./Firebase";
import firebase from "firebase";
import { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { Button, Fab, Radio, RadioGroup, Snackbar, Step, StepLabel, Stepper } from "@mui/material";
import { Home, Payment, ShoppingBag } from "@mui/icons-material";

export default function Cart() {

    const [user, setuser] = useState('');

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setuser(user)
            } else {

            }
        });
    }, [])

    var navi = useNavigate();

    const [cartcount, setcartcount] = useState([]);
    const [carttotal, setcarttotal] = useState([]);
    const [total, settotal] = useState(0);

    function getcart() {
        if (user) {
            db.collection("Users").doc(user.uid).collection("Cart").onSnapshot((succ) => {
                setcartcount(succ.docs);
                var t = 0;
                var ar = [];
                succ.forEach((scc) => {
                    t += (scc.data().Product.Selling * scc.data().Product.Bought)
                    var a = scc.data();
                    ar.push(Object.assign(a, {
                        CId: scc.id
                    }))
                })
                settotal(t);
                setcarttotal(ar);
            })
        }
    }

    // console.log(carttotal);

    useEffect(() => {
        getcart();
    }, [user])

    function dellfromcart(x) {
        db.collection("Users").doc(user.uid).collection("Cart").doc(x).delete().then((succ) => {
            setopenmessage("Removed From Cart");
            setopen(true);
        })
    }

    const [open, setopen] = useState(false);
    const [openmessage, setopenmessage] = useState("");

    const [addr, setaddr] = useState([]);

    function getaddress() {
        if (user) {
            db.collection("Users").doc(user.uid).collection("Address").onSnapshot((succ) => {
                if (succ.size > 0) {
                    setaddr(succ.docs);
                }
            })
        }
    }
    useEffect(() => {
        getaddress();
    }, [user])

    const [stp, setstp] = useState(1);

    const [seladdress, setseladdress] = useState('');
    const [seladdressid, setseladdressid] = useState('');

    // console.log(seladdress);

    // console.log(user);

    function placeorder() {
        if (cartcount.length < 0) {
            alert("Something Went Wrong Please try again");
        } else if (!seladdress) {
            alert("Something Went Wrong Please try again");
        } else {
            // alert("Ok");

            var batch = db.batch();
            var d = new Date();

            db.collection('FinalOrders').orderBy('date', 'desc').limit(1).get().then(function (suc) {
                var ord = 1;
                suc.forEach(function (succ) {
                    if (succ.exists) {
                        ord = succ.data().ono + 1;
                    }
                })

                var str = "" + ord
                var pad = "0000"
                var ans = pad.substring(0, pad.length - str.length) + str
                var yer = d.getFullYear().toString();
                var y = yer.substring(2, 4);
                var oid = "OID" + ans;
                var dd = String(d.getDate()).padStart(2, '0');
                var mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = d.getFullYear();
                var today = dd + '/' + mm + '/' + yyyy;
                var year = d.getFullYear();
                var month = (d.getMonth() + 1);

                // console.log(oid);


                var f_ord = db.collection('FinalOrders').doc();

                // console.log({
                //     Oid: oid,
                //     Uid: user.uid,
                //     Address: seladdress.data(),
                //     Products: carttotal,
                //     date: firebase.firestore.FieldValue.serverTimestamp(),
                //     Year: year,
                //     Month: month,
                //     FullDate: today,
                //     Payment: 'COD',
                //     totalProducts: carttotal.length,
                //     ono: ord,
                //     orderStatus: 0
                // });

                batch.set(f_ord, {
                    Oid: oid,
                    Uid: user.uid,
                    Address: seladdress.data(),
                    Products: carttotal,
                    date: firebase.firestore.FieldValue.serverTimestamp(),
                    Year: year,
                    Month: month,
                    FullDate: today,
                    Payment: 'COD',
                    totalProducts: carttotal.length,
                    ono: ord,
                    orderStatus: 0
                })

                cartcount.map((dell) => {
                    var qr = db.collection("Users").doc(user.uid).collection("Cart").doc(dell.id);
                    batch.delete(qr);
                    // console.log(qr);
                })

                // console.log(batch);

                batch.commit().then((succ) => {
                    // console.log("Ok")
                    navi("/Success");
                })
            })
        }
    }

    // var loc = useLocation();

    // function disableBack() { window.history.forward(); }
    // setTimeout("disableBack()", 0);
    // loc.onunload = function () { null };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setopen(false)}
                message={openmessage}
            />

            <Navbar />

            <div className="container-fluid">
                {cartcount.length <= 0 ? (
                    <>
                        <div className="alert alert-danger text-center">No Product in Cart</div>
                    </>
                ) : (
                    <div className="row m-0 justify-content-center">
                        <div className="col-lg-12 p-5">
                            <Stepper>
                                <Step>
                                    <Fab color={stp == 1 && "primary"}>
                                        <ShoppingBag />
                                    </Fab>
                                </Step>
                                <Step>
                                    <Fab color={stp == 2 && "primary"}>
                                        <Home />
                                    </Fab>
                                </Step>
                                {/* <Step>
                                    <Fab color={stp == 3 && "primary"}>
                                        <Payment />
                                    </Fab>
                                </Step> */}
                            </Stepper>
                        </div>
                        <div className="col-lg-12">
                            {stp == 1 && (
                                <>
                                    <div className="row m-0">
                                        <div className="col-lg-8">
                                            <div className="card shadow-lg">
                                                <div className="card-body">
                                                    <h4>My Cart</h4>
                                                    <hr />
                                                    {cartcount.map((row) => (
                                                        <div key={row.id} className="col-lg-12 text-decoration-none">
                                                            <div className="row m-0 hover p-2">
                                                                <div className="col-lg-2 col-md-4 col-sm-4 col-4">
                                                                    <Link Link to={"/ProductDetail?p=" + row.data().ProductId}>
                                                                        <img src={row.data().Product.Image[0]} className="img-fluid" />
                                                                    </Link>
                                                                </div>
                                                                <div className="col-lg-10 col-md-8 col-sm-8 col-8">
                                                                    <Button onClick={() => dellfromcart(row.id)} variant="contained" className="float-right">Remove From Cart</Button>

                                                                    <span className="text-capitalize">{row.data().Name}</span>
                                                                    <br />
                                                                    {row.data().Product.Material}
                                                                    <br />
                                                                    ₹{Number(row.data().Product.Selling).toFixed(2)} <del>₹{Number(row.data().Product.MRP).toFixed(2)}</del>
                                                                    <br />
                                                                    Quantity: {row.data().Product.Bought}
                                                                    <br />
                                                                    {row.data().Product.Size}
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* Link to={"/ProductDetail?p=" + row.data().ProductId} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <table className="table table-borderless">
                                                        <tr>
                                                            <th><h5>Price Details</h5></th>
                                                        </tr>
                                                        <tr>
                                                            <th>Count</th>
                                                            <th className="text-right">Total</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Price ({cartcount.length} items)</th>
                                                            <td align="right">₹{total.toFixed(2)}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <Button variant="contained" color="warning" disabled={stp == 1 && true} onClick={() => setstp(stp - 1)}>Previous</Button>
                                    <Button className="float-right" color="primary" variant="contained" onClick={() => setstp(stp + 1)}>Choose Address</Button>
                                </>
                            )}
                            {stp == 2 && (
                                <>
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            <p className="text-center h4 font-weight-bolder">
                                                Select Address
                                            </p>
                                            <Link className="m-1 btn btn-dark rounded" to="/Profile">Add New Address</Link>
                                            <hr />
                                            <ul className="list-group">
                                                {addr.map((rows) => (
                                                    <li className={rows.id == seladdressid ? "list-group-item bg-success text-light" : "list-group-item"}>
                                                        {/* <Button className={rows == seladdress ? "text-light float-right border-light" : "text-danger float-right"} variant="outlined" onClick={() => setseladdress(rows)}>Select</Button> */}
                                                        <button onClick={() => [setseladdress(rows), setseladdressid(rows.id)]} className={rows == seladdress ? "btn btn-outline-light float-right" : "btn btn-outline-dark float-right"}>Select</button>
                                                        {rows.data().name}
                                                        <br />
                                                        {rows.data().email}
                                                        <br />
                                                        {rows.data().contact}
                                                        <br />
                                                        {rows.data().address} ,{rows.data().pincode}, {rows.data().city}, {rows.data().state}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <br />
                                    <Button variant="contained" color="warning" disabled={stp == 1 && true} onClick={() => setstp(stp - 1)}>Previous</Button>
                                    <Button size="large" disabled={!seladdress && true} className="float-right" color="primary" variant="contained" onClick={placeorder}>Place Order</Button>
                                </>
                            )}

                        </div>
                    </div>
                )}
            </div>

            <Footer />

            {/* <!-- Back to Top --> */}
            <a href="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></a>


        </>
    )
}