import { Button } from "@mui/material";
import { ReactSession } from 'react-client-session';
import { useNavigate } from "react-router-dom";
import { db } from "../Firebase";
import { useEffect } from "react";

export default function AdminLog() {

    const navigate = useNavigate();

    function makeLogin(e) {
        e.preventDefault();
        var data = new FormData(e.currentTarget);
        db.collection("Admin_Login").where("Email", "==", data.get('username')).where("Password", "==", data.get('password')).get().then((qq) => {
            if (qq.size == 0) {
                alert('Wrong Id or Password');
            } else {
                ReactSession.set("username", "ADMIN@8968555");
                // localStorage.setItem('AdminLogin', 'ADMIN@8968555');
                navigate('/Dashboard');
            }
        })
    }

    const adminname = ReactSession.get("adminusername");

    console.log(adminname);

    useEffect(() => {
        if (adminname == 'ADMIN@8968555') {
            navigate('/Dashboard');
        }
    }, [])


    return (
        <>
            <div className="row m-0 h100 justify-content-center align-items-center">
                <div className="col-lg-3">
                    <div className="card">
                        <div className="card-header pb-0">
                            <p className="h4 text-center">Admin Login</p>
                        </div>
                        <div className="card-body">
                            <form onSubmit={makeLogin}>
                                <input type="text" name="username" className="form-control my-2" placeholder="Username" />
                                <input type="text" name="password" className="form-control my-2" placeholder="User Password" />
                                <Button type="submit" variant="contained">Login</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}