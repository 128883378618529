import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ReactSession } from 'react-client-session';

import AdminLogin from './adminlogin/AdminLogin';
import ViewDashboard from './adminlogin/Dashboard';
import ViewCategory from './adminlogin/Category';
import SelectCategory from './adminlogin/SelectCat';
import ViewProduct from './adminlogin/Product';
import ViewProduct2 from './adminlogin/Product2';
import ViewOneProduct from './adminlogin/ProductView';
import ViewOneProduct2 from './adminlogin/ProductView2';
import ViewOneProduct3 from './adminlogin/ProductView3';
import EditOneProduct from './adminlogin/StockEdit';
import ViewBrands from './adminlogin/Brands';
import Userslist from './adminlogin/Userslist';
import Orderslist from './adminlogin/Orderslist';
import Vieworder from './adminlogin/Vieworder';
import Log from './adminlog/Log';
import AdminLog from './adminlog/AdminLog';
import Dashboard from './adminlog/Dashboard';
import Category from './adminlog/Category';
import SubCategory from './adminlog/SubCategory';
import Products from './Products';
import ProductDetail from './ProductDetail';
import Login from './Login';
import Cart from './Cart';
import Profile from './Profile';
import Success from './Success';
import Orders from './Orders';
import OrderDetail from './OrderDetail';

function App() {
  ReactSession.setStoreType("localStorage");
  // ReactSession.set("username", "Bob");
  // ReactSession.remove('username');

  return (
    <div>
      {/* <Home /> */}

      <Router>
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/Products' element={<Products />} />
          <Route path='/ProductDetail' element={<ProductDetail />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/Cart' element={<Cart />} />
          <Route path='/Profile' element={<Profile />} />
          <Route path='/Success' element={<Success />} />
          <Route path='/Orders' element={<Orders />} />
          <Route path='/OrderDetail' element={<OrderDetail />} />


          <Route path='/AdminLog' element={<AdminLog />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          <Route path='/Category' element={<Category />} />
          <Route path='/SubCategory' element={<SubCategory />} />



          <Route path='/Adminlogin' element={<AdminLogin />} />
          <Route path='/AdminDashboard' element={<ViewDashboard />} />
          <Route path='/AdminCategory' element={<ViewCategory />} />
          <Route path='/AdminSelect' element={<SelectCategory />} />
          <Route path='/AdminProduct' element={<ViewProduct />} />
          <Route path='/AdminProduct/View' element={<ViewProduct2 />} />
          <Route path='/View' element={<ViewOneProduct />} />
          <Route path='/Edit' element={<ViewOneProduct2 />} />
          <Route path='/Keyword' element={<ViewOneProduct3 />} />
          <Route path='/StockEdit' element={<EditOneProduct />} />
          <Route path='/Brands' element={<ViewBrands />} />
          <Route path='/Userslist' element={<Userslist />} />
          <Route path='/Orderslist' element={<Orderslist />} />
          <Route path='/Vieworder' element={<Vieworder />} />

        </Routes>
      </Router>


    </div>
  );
}

export default App;
