import { Logout, Person, Dashboard, Category, ProductionQuantityLimits, LocalGroceryStore, Menu } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import React from "react";
import Header from "./Header";
import { styled, useTheme } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

function ViewDashboard() {
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Header />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    Hello
                </Box>
            </Box>
        </>
    )
}

export default ViewDashboard;