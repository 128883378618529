import React, { useEffect, useRef, useState } from "react";
import { Logout, Person, Dashboard, Category, ProductionQuantityLimits, LocalGroceryStore, Menu, Delete, Edit, ArrowCircleRight, Image, Camera } from "@mui/icons-material";
import { Button, CircularProgress, Divider, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import TopBarAdmin from "./Header";
import SideBarAdmin from "./Sidebar";
import boiler from './icons/boiler.png'
import firebase from "firebase";
import { db, storage } from "../Firebase";
import imageCompression from "browser-image-compression";
import { Link, useNavigate } from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import HTMLReactParser from "html-react-parser";



function EditOneProduct(){


    const [prog,setprog] = useState('');

    const [progbar, setprogbar] = useState(false);
    const [btn, setbtn] = useState(true);

    const navigate = useNavigate();

      const [cat,setcat] = React.useState([]);
      const getCat = () => {
        // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
          db.collection("MynewCategory").onSnapshot((querySnapshot) =>{
            var allcat = [];
            querySnapshot.forEach((docs) => {
                allcat.push(docs);
            })
            setcat(allcat);
        });
      }
      var id = new URLSearchParams(window.location.search).get('id');
      var pid = new URLSearchParams(window.location.search).get('pid');

      const [sub,setsub] = React.useState(false);

    const onSubmit = (event) => {
        setsub(true);
        event.preventDefault();
        setsub(false);    
    }


    const [data, setdata] = useState(); 

    const [mrp,setmrp] = useState('');
    const [selling,setselling] = useState('');
    const [off,setoff] = useState('');
    const [material,setmaterial] = useState('');
    const [size,setsize] = useState([]);
    const [stock,setstock] = useState([]);
    const [allimages,setimages] = useState([]);


    function findProduct(){
        db.collection('All-AllProducts').doc(pid).collection('Stock').doc(id).onSnapshot(function(suc){
            setmrp(suc.data().MRP);
            setselling(suc.data().Selling);
            setoff(suc.data().Percentage);
            setmaterial(suc.data().Material);
            setsize(suc.data().Size);
            setstock(suc.data().Stock);
            setimages(suc.data().Image);
        })
    }


    useEffect(() => {
        findProduct();
    }, [id])


    const onSubmit2 = (event) => {
        var batch = db.batch();
        setsub(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // var category = data.get('category');
        var mrp = parseFloat(data.get('mrp'));
        var selling = parseFloat(data.get('selling'));
        var material = data.get('material');
        var size = data.get('size')
        var description = data.get('description');
        var image = data.get('image1');
        var stock = data.get('stock');
        var percent = parseFloat(data.get('off'));

        console.log(percent);
        console.log(selling);
        console.log(mrp);


        if(percent == '' || percent < 0){
          var newpercent = (selling*100)/mrp;
          var percent = (100-newpercent);
          console.log(newpercent);
        }else{
          console.log(percent);
          console.log(mrp);
          var newselling = (percent/100)*mrp;
          console.log(newselling);
          var selling = mrp-newselling;
        }

        selling = (parseFloat(selling).toFixed(2));

        percent = parseFloat(percent);

        // console.log(selling);

        // console.log(percent);


        // var newpercent = (parseFloat(selling)*100)/parseFloat(mrp);

        // var percent = 100-newpercent;

        // percent = parseFloat(percent);

        var query2 = db.collection('All-AllProducts').doc(pid).collection('Stock').doc(id)

        batch.update(query2,{
            MRP:parseFloat(mrp),
            Selling:parseFloat(selling),
            Material:material,
            Percentage:parseFloat(percent).toFixed(2),
            Size:size,
            Stock:parseInt(stock),
        })
                        console.log(id);

                          batch.commit().then((suc) => {
                            event.target.reset();
                            setsub(false);
                            alert('Stock Updated');
                            findProduct();
                          })
    
    }


    const [allstock, setallstock] = React.useState([]);

    const getStock = () => {
      db.collection('All-AllProducts').doc(id).collection('Stock').onSnapshot(function(succ){
        var ar = [];
        succ.forEach(function(succc){
          ar.push(succc);
        })
        setallstock(ar);
      })
    }
  
    function delPro(x){
    //   db.collection("All-AllProducts").doc(id).collection('Stock').doc(x.id).delete();
    //   storage.refFromURL(x.data().Image).delete();
    }

    function editPro(x){
      // db.collection("All-AllProducts").doc(id).collection('Stock').doc(x.id).delete();
      // storage.refFromURL(x.data().Image).delete();
    }


    // const inputFileRef = useRef( null );

    // function opencamera(){
    //   inputFileRef.current.click();
    // }

    // const [nimg, setnimg] = useState();

    // function setnewimg(x){
    //   console.log(x);
    //   setnimg(x);
    // }


      React.useEffect(() => {
        getCat();
        // getsubsubCat()
        getStock()
      }, [])






      const inputFileRef = useRef( null );

      function opencamera(){
        inputFileRef.current.click();
      }
  
      const [nimg, setnimg] = useState();
  
      function setnewimg(x){
        console.log(x);
        setnimg(x);
      }
  
      function setdel(x){
        let text = "Press Ok to Confirm.";
        if (window.confirm(text) == true) {
          // alert(x);
          var index = allimages.indexOf(x);
          if(index > -1){
              allimages.splice(index, 1)
          }
          // console.log(allimages);
          db.collection('All-AllProducts').doc(pid).collection('Stock').doc(id).update({
            Image:allimages
          }).then(function(succ){
              storage.refFromURL(x).delete();
          })
        }else{
          //
        }
      }
  
      function addimage(){
          // console.log(nimg);
          // allimages.push(nimg);
          // console.log(allimages);
  
  
          var options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1000,
              useWebWorker: true
            }
        
            var metadata = {
              contentType: 'image/jpeg'
            };
        
            // console.log(category.split("|"));
        
            var random = Math.floor(Math.random() * (1000000000 - 100000000)) + 100000000;
        
    
            var uploadTask = storage.ref().child('images/'+ random + '/' + nimg.name).put(nimg, metadata);
    
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                      (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                          case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                          case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                        }
                      }, 
                      (error) => {
                        switch (error.code) {
                          case 'storage/unauthorized':
                            break;
                          case 'storage/canceled':
                            break;
                          case 'storage/unknown':
                            break;
                        }
                      }, 
                      () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                          console.log('image1 available at', downloadURL);
  
                              console.log(allimages);
                              console.log(downloadURL);
                              allimages.push(downloadURL);
                              console.log(allimages);
                              db.collection('All-AllProducts').doc(pid).collection('Stock').doc(id).update({
                                  Image:allimages
                              }).then(function(succ){
                                  alert('Image Added');
                                  setnimg(null);
                              })
  
                        })
  
                      })
  
      }








    return(
        <>
        <TopBarAdmin/>
        <Divider/>
            <div className="row">
                {/* <SideBarAdmin/> */}
                <div className="col-lg-12">
                <br/>
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-left">
                        <li class="page-item"><Link class="page-link" to="/AdminProduct">Add New Product</Link></li>
                        <li class="page-item"><Link class="page-link" to="/AdminProduct/View">View Product</Link></li>
                    </ul>
                </nav>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="card">
                            <div className="card-header">

                              <div className="col">
                                    <h5 style={{textTransform:'capitalize'}}>Update Stock</h5>
                                </div>

                            </div>
                            <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">

                                <form onSubmit={onSubmit2} encType="multipart/form-data" className='row'>
                                <div className="col-lg-12" style={{padding:3}}>
                                <small>MRP</small>
                                    <TextField InputLabelProps={{shrink: true,}} type='text' name='mrp' id='mrp' margin='normal' fullWidth size="small" label="MRP" required onChange={(e) => setmrp(e.target.value)} value={mrp} />
                                </div>
                                <div className="col-lg-12" style={{padding:3}}>
                                {/* <small>Off %</small> */}
                                    <TextField InputLabelProps={{shrink: true,}} type='number' name='off' id='off' margin='normal' fullWidth size="small" label="Off %" required onChange={(e) => setoff(e.target.value)} value={off}/>
                                </div>
                                <div className="col-lg-12" style={{padding:3}}>
                                <small>Selling Price</small>
                                    <TextField InputLabelProps={{shrink: true,}} type='text' name='selling' id='selling' margin='normal' fullWidth size="small" label="Selling Price" required onChange={(e) => setselling(e.target.value)} value={selling}/>
                                </div>
                                <div className="col-lg-12" style={{padding:3}}>
                                <small>Material</small>
                                    <TextField InputLabelProps={{shrink: true,}} type='text' name='material' id='material' margin='normal' fullWidth size="small" label="Material" required onChange={(e) => setmaterial(e.target.value)} value={material} />
                                </div>
                                <div className="col-lg-12" style={{padding:3}}>
                                <small>Spcify Size</small>
                                    <TextField InputLabelProps={{shrink: true,}} type='text' name='size' id='size' margin='normal' fullWidth size="small" label="Size" required onChange={(e) => setsize(e.target.value)} value={size} />
                                </div>
                                <div className="col-lg-12" style={{padding:3}}>
                                <small>Stock</small>
                            <TextField
                                type="number"
                                size="small"
                                fullWidth
                                id="stock"
                                name="stock"
                                variant="outlined"
                                margin='normal'
                                required
                                label="Stock"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) => setstock(e.target.value)} value={stock}
                            />
                                </div>

                            {sub ? (
                              <>
                                Uploading <CircularProgress/>
                              </>
                            ) : (
                              <Button variant='contained' type="submit">Submit</Button>
                            )}
                        </form>
                    </div>
                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                    <div className="row">
                              <div className="card col-lg-4">
                                <div className="card-body">
                                    <label><b>Choose File</b></label><br/>
                                    <input type='file' onChange={(e) => setnewimg(e.target.files[0])} className="form-control" ref={inputFileRef} id="image" style={{display:'none'}} />
                                    <button className="btn btn-danger" onClick={opencamera}><Camera/></button>
                                    &nbsp;&nbsp;
                                    {nimg && (<>
                                      <button id="bt" onClick={addimage} className="btn btn-success">upload</button>
                                      <br/><br/>
                                      <img src={URL.createObjectURL(nimg)} className='img-responsive' />
                                    </>)}
                                </div>
                              </div>
                              <div className="col-lg-8">
                                  <div className="row">
                                      {/* {allimages.length} */}
                                  {allimages.map((row) => (
                                  <div className="col-lg-6" key={row.id}>
                                    <div className="card">
                                        <div className="card-body">
                                            <img src={row} className='img-responsive' />
                                        </div>
                                        <div className="card-footer">
                                            <Button onClick={() => setdel(row)}><Delete/></Button>
                                        </div>
                                    </div>
                                  </div>
                                  ))}
                                  </div>


                              </div>
                            </div>
                        </div>



                </div>

                </div>
            </div>
        </>
    )
}

export default EditOneProduct;