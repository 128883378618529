import React, { useState } from "react";
import { Logout, Person, Dashboard, Category, ProductionQuantityLimits, LocalGroceryStore, Menu, Delete, Edit, ArrowCircleRight } from "@mui/icons-material";
import { Box, Chip, Divider } from "@mui/material";
// import TopBarAdmin from "./Header";
// import SideBarAdmin from "./Sidebar";
import boiler from './icons/boiler.png'
import firebase from "firebase";
import { db, storage } from "../Firebase";
import imageCompression from "browser-image-compression";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Header from "./Header";

import { styled, useTheme } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


function SelectCategory() {


  const [prog, setprog] = useState('');

  const [progbar, setprogbar] = useState(false);
  const [btn, setbtn] = useState(true);



  const [onlysubcat, setonlysubcat] = React.useState([]);
  const onSubmit = (event) => {


    setprogbar(true);
    setbtn(false);

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log(data.get('selcategory'));
    // console.log(data.get('subcategory'));
    var sub = db.collection("All-Sub-Category").doc(data.get('selcategory'));
    sub.get().then((docSnap) => {
      if (docSnap.exists) {

        const cid = docSnap.id;
        const array = docSnap.data().Values;

        // console.log(array);

        const index = array.indexOf(data.get('subcategory').toLocaleLowerCase());
        if (index > -1) {

          alert("Sorry, This Category is already in List");
          setprogbar(false);
          setbtn(true);

        } else {
          // console.log('no');

          var ar = docSnap.data().Values;
          ar.push(data.get('subcategory').toLocaleLowerCase())
          sub.update({
            Values: ar
          })
            .then((tru) => {
              alert("Sub Category Added");
              event.target.reset();
              setprogbar(false);
              setbtn(true);
            }).catch((err) => {
              alert("Error, Contact Admin");
              setprogbar(false);
              setbtn(true);
            })


        }

      } else {
        // console.log('no');
        db.collection("All-Sub-Category").doc(data.get('selcategory')).set({
          Values: [data.get('subcategory').toLocaleLowerCase()],
          Category: data.get('selcategory')
        })
          .then((tru) => {
            alert("Sub Category Added");
            setprogbar(false);
            setbtn(true);
          }).catch((err) => {
            alert("Error, Contact Admin");
            setprogbar(false);
            setbtn(true);
          })
      }
    })
  }








  //   const [cat,setcat] = React.useState([]);
  //   const getCat = () => {
  //     // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
  //       db.collection("MynewCategory").onSnapshot((querySnapshot) =>{
  //         var allcat = [];
  //         querySnapshot.forEach((docs) => {
  //             allcat.push(docs);
  //         })
  //         setcat(allcat);
  //     });
  //   }


  const [cat, setcat] = React.useState([]);
  const getCat = () => {
    // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
    db.collection("MynewCategory").onSnapshot((querySnapshot) => {
      var allcat = [];
      querySnapshot.forEach((docs) => {
        allcat.push(docs);
      })
      setcat(allcat);
    });
  }

  const [subcat, setsubcat] = React.useState([]);
  const [subcatlist, setsubcatlist] = React.useState([]);
  const getSubCat = () => {
    // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
    db.collection("All-Sub-Category").onSnapshot((querySnapshot) => {
      var allsubcat = [];
      var allsubcatlist = [];
      querySnapshot.forEach((docs) => {
        allsubcat.push(docs);
        // console.log(docs.data().Values);
        allsubcatlist.push(docs.data().Values);
        // console.log(allsubcatlist);
      })
      setsubcat(allsubcat);
      setsubcatlist(allsubcatlist);
    });
  }


  const subcatDel = (x) => {
    //   alert(x);
    db.collection("All-Sub-Category").doc(x).delete();
    // deleteDoc(doc(db, "Category", x));
  }

  const subcatDelete = (x, y) => {
    const cid = x.id;
    const array = x.data().Values;

    const index = array.indexOf(y);
    if (index > -1) {
      array.splice(index, 1);
    }
    db.collection("All-Sub-Category").doc(cid).update({
      Values: array
    }).then((suc) => {
      alert("Sub Category Deleted");
    })
  }

  var arrToConvert = subcatlist;
  var newArr = [];

  for (var i = 0; i < arrToConvert.length; i++) {
    newArr = newArr.concat(arrToConvert[i]);
  }




  const onSubmit2 = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log(data.get('selcategory'));
    // console.log(data.get('subcategory'));
    var sub = db.collection("All-Sub-Sub-Category").doc(data.get('selcategory'));
    sub.get().then((docSnap) => {
      if (docSnap.exists) {

        const cid = docSnap.id;
        const array = docSnap.data().Values;

        // console.log(array);

        const index = array.indexOf(data.get('subcategory').toLocaleLowerCase());
        if (index > -1) {
          alert("Sorry, This Category is already in List");
        } else {
          // console.log('no');

          var ar = docSnap.data().Values;
          ar.push(data.get('subcategory').toLocaleLowerCase())

          sub.update({
            Values: ar,
          })
            .then((tru) => {
              alert("Sub Sub Category Added");
              event.target.reset();
            }).catch((err) => {
              alert("Error, Contact Admin");
            })


        }
        console.log('no')

      } else {

        console.log('yes')
        var num = 1;
        db.collection('All-Sub-Sub-Category').orderBy('number', 'desc').limit(1).get().then(function (suc) {
          if (suc.size == 0) {
            num = 1;
          }
          suc.forEach(function (succ) {
            num = succ.data().number + 1;
          })

          // console.log('no');
          db.collection("All-Sub-Sub-Category").doc(data.get('selcategory')).set({
            Values: [data.get('subcategory').toLocaleLowerCase()],
            SubCategory: data.get('selcategory'),
            number: num,
          })
            .then((tru) => {
              alert("Sub Sub Category Added");
            }).catch((err) => {
              alert("Error, Contact Admin");
            })

        })

      }
    })
  }



  const [subsubcat, setsubsubcat] = React.useState([]);
  const [subsubcatlist, setsubsubcatlist] = React.useState([]);
  const getsubsubCat = () => {
    // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
    db.collection("All-Sub-Sub-Category").onSnapshot((querySnapshot) => {
      var allsubsubcat = [];
      var allsubsubcatlist = [];
      querySnapshot.forEach((docs) => {
        allsubsubcat.push(docs);
        // console.log(docs.data().Values);
        allsubsubcatlist.push(docs.data().Values);
        // console.log(allsubsubcatlist);
      })
      setsubsubcat(allsubsubcat);
      setsubsubcatlist(allsubsubcatlist);
    });
  }


  const subsubcatDelete = (x, y) => {
    // alert(x);
    // alert(y);

    const cid = x.id;
    const array = x.data().Values;

    // console.log(array);

    const index = array.indexOf(y);
    if (index > -1) {
      array.splice(index, 1);
    }

    // array = [2, 9]
    // console.log(array); 
    db.collection("All-Sub-Sub-Category").doc(cid).update({
      Values: array
    }).then((suc) => {
      alert("Sub-Sub-Category Updated");
    })
  }

  const subsubcatDel = (x) => {
    //   alert(x);
    db.collection("All-Sub-Sub-Category").doc(x).delete();
    // deleteDoc(doc(db, "Category", x));
  }



  React.useEffect(() => {
    getCat();
    getSubCat();
    getsubsubCat();
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />

          {/* <TopBarAdmin />
          <Divider /> */}
          <div className="row">
            {/* <SideBarAdmin/> */}
            <div className="col-lg-12">

              <br />

              <div className="row m-0 justify-content-around">

                <div className="col-lg-5 col-md-5 ">
                  <div className="card">
                    <div className="card-body">
                      <div className="col">
                        <h5>Manage Sub Category</h5>
                      </div>

                      <form onSubmit={onSubmit}>
                        <select className="form-control-ui" name="selcategory">
                          {cat.map((row) => (
                            <option key={row.id}>{row.data().Value}</option>
                          ))}
                        </select>
                        <input type="text" className="form-control-ui third" name="subcategory" placeholder="Title" required />
                        {btn && (<>
                          <input type="submit" className="form-control-btn fourth" value="Add Sub Category" />
                        </>)}
                        {progbar && (
                          <div className="col-lg-12">
                            <center><CircularProgress /></center>
                          </div>
                        )}
                      </form>

                    </div>
                  </div>

                  <br />

                  {subcat.map((row) => (
                    <div className="card" key={row.id} style={{ marginBottom: 5 }}>
                      <div className="card-header">
                        {/* <button className='close' onClick={() => subcatDel(row.id)}><Delete/></button> */}
                        {row.id}
                      </div>
                      <div className="card-body">

                        {row.data().Values.map((rows) => (
                          <>
                            <Chip label={rows} onDelete={() => subcatDelete(row, rows)} style={{ margin: 3, fontSize: 18, textTransform: 'capitalize' }} />
                          </>
                        ))}

                      </div>
                    </div>
                  ))}

                </div>

                <div className="col-lg-5 col-md-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="col">
                        <h5>Manage Sub Sub Category</h5>
                      </div>

                      <form onSubmit={onSubmit2}>
                        <select className="form-control-ui" name="selcategory">
                          {newArr.map((row) => (
                            <option key={row}>{row}</option>
                          ))}
                        </select>
                        <input type="text" className="form-control-ui third" name="subcategory" placeholder="Title" required />
                        {btn && (<>
                          <input type="submit" className="form-control-btn fourth" value="Add Sub Category" />
                        </>)}
                        {progbar && (
                          <div className="col-lg-12">
                            <center><CircularProgress /></center>
                          </div>
                        )}
                      </form>

                    </div>
                  </div>

                  <br />

                  {subsubcat.map((row) => (
                    <div className="card" key={row.id} style={{ marginBottom: 5 }}>
                      <div className="card-header">
                        <button className='close' onClick={() => subsubcatDel(row.id)}><Delete /></button>
                        {row.id}
                      </div>
                      <div className="card-body">

                        {row.data().Values.map((rows) => (
                          <>
                            <Chip label={rows} onDelete={() => subsubcatDelete(row, rows)} style={{ margin: 3, fontSize: 18, textTransform: 'capitalize' }} />
                          </>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default SelectCategory;