import React, { useEffect, useState } from "react";
import { Logout, Person, Dashboard, Category, ProductionQuantityLimits, LocalGroceryStore, Menu, Delete, Edit, ArrowCircleRight } from "@mui/icons-material";
import { Chip, Divider, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
// import TopBarAdmin from "./Header";
// import SideBarAdmin from "./Sidebar";
import boiler from './icons/boiler.png'
import firebase from "firebase";
import { db, storage } from "../Firebase";
import imageCompression from "browser-image-compression";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import Header from "./Header";
import { styled, useTheme } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


function ViewBrands() {


    const navigate = useNavigate();
    const [prog, setprog] = useState('');

    const [progbar, setprogbar] = useState(false);
    const [btn, setbtn] = useState(true);

    const onSubmit = (event) => {

        setprogbar(false);
        // setbtn(false);
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        var cat = data.get('category').toLowerCase();

        // console.log(cat.split(','));
        // const index = brands.indexOf(cat);
        // if (index > -1) {
        //     alert('Already a brand');
        // }else{

        var newbrand = brands.concat(cat.split(','))
        console.log(newbrand);
        db.collection("All-Sub-Sub-Category").doc(id).update({
            Brands: newbrand
        }).then((succ) => {
            alert('Brand added');
            event.target.reset();
            setPersonName([]);
            // selected([]);
        })

        // }


    }

    var id = new URLSearchParams(window.location.search).get('id');

    const [subsubcatlist, setsubsubcatlist] = React.useState([]);
    const getsubsubCat = () => {
        db.collection("All-Sub-Sub-Category").onSnapshot((querySnapshot) => {
            var allsubsubcatlist = [];
            querySnapshot.forEach((docs) => {
                allsubsubcatlist.push(docs.id);
            })
            setsubsubcatlist(allsubsubcatlist);
        });
    }

    const [brands, setbrands] = React.useState([]);
    const getbrands = () => {
        if (id) {
            db.collection("All-Sub-Sub-Category").doc(id).onSnapshot((querySnapshot) => {
                if (querySnapshot.data().Brands) {
                    setbrands(querySnapshot.data().Brands);
                    console.log(querySnapshot.data().Brands);
                } else {
                    setbrands([]);
                }
            });
        }
    }

    function viewcat(x) {
        // alert('hi');
        var path = '/Brands?id=' + x;
        navigate(path);
    }


    React.useEffect(() => {
        // if(id){
        getbrands();
        // }
    }, [id])

    React.useEffect(() => {
        getsubsubCat();
    }, [])


    function deletefun(x) {
        if (window.confirm("Please Confirm to Delete")) {
            // alert(x);
            const index = brands.indexOf(x);
            if (index > -1) {
                brands.splice(index, 1);
            }

            db.collection("All-Sub-Sub-Category").doc(id).update({
                Brands: brands
            })

        }
    }



    const [Gbrand, setGbrand] = useState([]);
    function globalbrands() {
        db.collection('Brands').doc('mybrands').onSnapshot(function (succ) {
            if (succ.exists) {
                setGbrand(succ.data().Value);
            } else {
                setGbrand('no');
            }
        })
    }

    useEffect(() => {
        globalbrands();
    }, [])


    function delGbrnad(x) {
        if (window.confirm('Make sure this Brand (' + x + ') is not in the any Category or Product.\nConfirm to Delete')) {
            const index = Gbrand.indexOf(x);
            if (index > -1) {
                Gbrand.splice(index, 1);
            }
            db.collection("Brands").doc('mybrands').update({
                Value: Gbrand
            })
        }
    }


    function addbrand(e) {
        e.preventDefault();
        var data = new FormData(e.currentTarget);

        var name = data.get('brandname').toLowerCase();
        console.log(name);
        console.log(Gbrand);
        if (Gbrand != 'no') {
            // console.log('yes');
            if (Gbrand.includes(name)) {
                alert('Already Brand Added');
                e.target.reset();
                e.target.brandname.focus();
            } else {
                Gbrand.push(name);
                db.collection('Brands').doc('mybrands').update({
                    Value: Gbrand,
                }).then((succ) => {
                    alert('Added');
                    e.target.reset();
                    e.target.brandname.focus();
                })
            }
        } else {
            // console.log('no');
            db.collection('Brands').doc('mybrands').set({
                Value: [name],
            }).then((succ) => {
                alert('Added');
                e.target.reset();
                e.target.brandname.focus();
            })

        }

    }










    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    // brands
    // Gbrand

    const filterArray = (arr1, arr2) => {
        const filtered = arr1.filter(el => {
            return arr2.indexOf(el) === -1;
        });
        return filtered;
    };


    const names = filterArray(Gbrand, brands);


    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    //   console.log(personName);
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };



    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Header />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />

                    {/* <TopBarAdmin /> */}
                    {/* <Divider /> */}
                    <div className="row">
                        {/* <SideBarAdmin/> */}
                        <div className="col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <h4>Add Brands</h4>
                                    <form onSubmit={addbrand}>
                                        <div className="form-group">
                                            <input type={'text'} name='brandname' className='form-control-ui' required placeholder="Brand Name" />
                                        </div>
                                        <div className="form-group">
                                            <input type={'submit'} className='form-control-btn' value={'Add'} />
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer">
                                    <h5>All Brand</h5>
                                    {Gbrand.map((row) => (
                                        <li className="list-group-item">{row} <button onClick={() => delGbrnad(row)} className="btn pull-right" style={{ marginTop: -10 }}><Delete /></button></li>
                                    ))}
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-9">
                            <br />
                            <div className="row">

                                {subsubcatlist.map((row) => (
                                    <>{row == id ? (<>
                                        <Chip label={row} color='secondary' style={{ margin: 5 }} onClick={() => viewcat(row)} />
                                    </>) : (<>
                                        <Chip label={row} style={{ margin: 5 }} onClick={() => viewcat(row)} />
                                    </>)}
                                    </>
                                ))}


                            </div>

                            {id ? (

                                <div className="row">

                                    <div className="col-lg-4 col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="col">
                                                    <h5>Manage Brands for Filters</h5>
                                                </div>

                                                <form onSubmit={onSubmit}>
                                                    {/* <input type="text" className="form-control-ui third" name="category" placeholder="Title" required /> */}

                                                    {/* <InputLabel id="demo-multiple-chip-label">Chip</InputLabel> */}
                                                    <Select
                                                        multiple
                                                        fullWidth
                                                        name='category'
                                                        required
                                                        value={personName}
                                                        onChange={handleChange}
                                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                        renderValue={(selected) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {selected.map((value) => (
                                                                    <Chip key={value} label={value} />
                                                                ))}
                                                            </Box>
                                                        )}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {names.map((name) => (
                                                            <MenuItem
                                                                key={name}
                                                                value={name}
                                                                style={getStyles(name, personName, theme)}
                                                            >
                                                                {name}

                                                            </MenuItem>
                                                        ))}
                                                    </Select>


                                                    {btn && (<>
                                                        <input type="submit" className="form-control-btn fourth" value="Add Category" />
                                                    </>)}
                                                    {progbar && (
                                                        <div className="progress">
                                                            <div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: prog }}>
                                                                {prog}
                                                            </div>
                                                        </div>
                                                    )}
                                                </form>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-8 col-md-6">
                                        <div className="row">
                                            {brands.map((row) => (
                                                <><Chip label={row} style={{ margin: 5 }} onDelete={() => deletefun(row)} /></>
                                            ))}

                                        </div>
                                    </div>

                                </div>
                            ) : (<h3 className="alert alert-danger text-center" style={{ marginTop: 50 }}>Select a category to assign the brands</h3>)}

                        </div>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default ViewBrands;