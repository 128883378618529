import React, { useEffect, useRef, useState } from "react";
// import TopBarAdmin from "./Header";
import firebase from "firebase";
import { db, storage } from "../Firebase";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Card, CardContent, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";

import { styled, useTheme } from '@mui/material/styles';
import Header from "./Header";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

function Userslist() {

    const [users, setusers] = useState([]);
    function Userslists() {
        db.collection('User').onSnapshot((succ) => {
            var ar = [];
            succ.forEach((succc) => {
                ar.push(succc);
            })
            setusers(ar);
        })
    }


    useEffect(() => {
        Userslists();
    }, [])


    function del(x) {
        db.collection('User').doc(x).delete();
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Header />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    {/* <TopBarAdmin /> */}
                    {/* <Divider /> */}
                    <Card>
                        <CardContent>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Contact</TableCell>
                                            {/* <TableCell>Action</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{row.data().Name}</TableCell>
                                                <TableCell>{row.data().Email}</TableCell>
                                                <TableCell>{row.data().Contact}</TableCell>
                                                <TableCell>
                                                    <Button onClick={() => del(row.id)}><Delete /></Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>

                    </Card>
                </Box>
            </Box>
        </>
    )
}

export default Userslist;