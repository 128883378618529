import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { auth, db } from "./Firebase";
import firebase from "firebase";
import { useEffect, useState } from "react";
import HTMLReactParser from "html-react-parser";
import { Button, Fab, Radio, RadioGroup, Snackbar, Step, StepLabel, Stepper } from "@mui/material";
import { DocumentScanner, Home, Payment, ShoppingBag } from "@mui/icons-material";

export default function Orders() {

    const [user, setuser] = useState('');

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setuser(user)
            } else {

            }
        });
    }, [])


    // console.log(user.uid);

    const [data, setdata] = useState([])

    function getorders() {
        if (user) {
            db.collection("FinalOrders").where("Uid", "==", user.uid).get().then((succ) => {
                setdata(succ.docs);
            })
        }
    }
    useEffect(() => {
        getorders();
    }, [user])



    return (
        <>

            <Navbar />

            <div className="row m-0 justify-content-center">
                <div class="col-lg-12">

                    <table className="table">
                        {data.map((row) => (
                            <tr>
                                <td width={50}>{row.data().Oid}</td>
                                <td align="center" width={200}>
                                    <img src={row.data().Products[0].images[0]} className="navbar-brand-img" />
                                    {row.data().totalProducts > 1 && (<span className="badge">+{row.data().totalProducts}</span>)}
                                </td>
                                {/* <td>{row.data().totalProducts}</td> */}
                                <td>Delivered To : {row.data().Address.name}</td>
                                <td>
                                    {row.data().FullDate}
                                    <br />
                                    {row.data().Payment}
                                </td>
                                <td>
                                    Delivery Status
                                    <br />
                                    {row.data().orderStatus == 0 && (
                                        <span className="bg-info text-light badge">Order Placed</span>
                                    )}
                                    {row.data().orderStatus == 1 && (
                                        <span className="bg-info text-light badge">On the Way</span>
                                    )}
                                    {row.data().orderStatus == 2 && (
                                        <span className="bg-success text-light badge">Delivered</span>
                                    )}
                                </td>
                                <td>
                                    <Link to={"/OrderDetail?id=" + row.id} className="btn"><DocumentScanner /> Details</Link>
                                </td>
                            </tr>
                        ))}
                    </table>




                </div>
            </div>

            <Footer />

            {/* <!-- Back to Top --> */}
            <a href="#" className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></a>


        </>
    )
}