import { Box, Button, Chip, CircularProgress } from "@mui/material";
import Header from "./Header";
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../Firebase";
import firebase from "firebase";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


export default function SubCategory() {

    const [prog, setprog] = useState('');
    const [progbar, setprogbar] = useState(false);
    // setprogbar(false)
    var catid = new URLSearchParams(useLocation().search).get('id');

    var sub = db.collection("SubCategory").doc(catid);

    const onSubmit = (event) => {
        setprogbar(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        sub.get().then((docSnap) => {
            if (docSnap.exists) {
                const cid = docSnap.id;
                const array = docSnap.data().Values;
                const index = array.indexOf(data.get('subcategory').toLocaleLowerCase());
                if (index > -1) {
                    alert("Sorry, This Category is already in List");
                    setprogbar(false);
                } else {
                    var ar = docSnap.data().Values;
                    // ar.push(data.get('subcategory').toLocaleLowerCase())
                    sub.update({
                        Values: firebase.firestore.FieldValue.arrayUnion(data.get('subcategory').toLocaleLowerCase())
                    })
                        .then((tru) => {
                            alert("Sub Category Added");
                            event.target.reset();
                            setprogbar(false);
                        }).catch((err) => {
                            alert("Error, Contact Admin");
                            setprogbar(false);
                        })
                }
            } else {
                sub.set({
                    Values: [data.get('subcategory').toLocaleLowerCase()],
                    Category: catid
                })
                    .then((tru) => {
                        alert("Sub Category Added");
                        setprogbar(false);
                    }).catch((err) => {
                        alert("Error, Contact Admin");
                        setprogbar(false);
                    })
            }
        })
    }

    const [subcat, setsubcat] = useState([]);
    const [subcatlist, setsubcatlist] = useState([]);
    const getSubCat = () => {
        // onSnapshot(query(collection(db, "Category")), (querySnapshot) => {
        db.collection("SubCategory").onSnapshot((querySnapshot) => {
            var allsubcat = [];
            var allsubcatlist = [];
            querySnapshot.forEach((docs) => {
                allsubcat.push(docs);
                // console.log(docs.data().Values);
                allsubcatlist.push(docs.data().Values);
                // console.log(allsubcatlist);
            })
            setsubcat(allsubcat);
            setsubcatlist(allsubcatlist);
        });
    }

    useEffect(() => {
        getSubCat();
    }, [])

    const subcatDelete = (x) => {
        console.log(x);
        // const cid = x;
        // const array = x.data().Values;
        // const index = array.indexOf(y);
        // if (index > -1) {
        //     array.splice(index, 1);
        // }
        sub.update({
            Values: firebase.firestore.FieldValue.arrayRemove(x)
        }).then((suc) => {
            alert("Sub Category Deleted");
        })
    }


    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Header />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />

                    <div className="row m-0">
                        <div className="col-lg-6 col-md-6 p-0">
                            <div className="row m-0">
                                <div className="col-lg-6 col-md-12 p-1">
                                    <div className="card mb-2">
                                        <div className="card-body">
                                            <div className="col-12">
                                                <h4 className="text-capitalize">{catid} Sub Category</h4>
                                            </div>

                                            <form onSubmit={onSubmit}>
                                                <input type="text" className="form-control my-2" name="subcategory" placeholder="Title" required />
                                                {progbar ? (
                                                    <div className="col-lg-12">
                                                        <center><CircularProgress /></center>
                                                    </div>
                                                ) : (
                                                    <Button type="submit" variant="contained">Add Sub Category</Button>
                                                )}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 p-1">

                                    {subcat.map((row) => (
                                        <div className="card" key={row.id} style={{ marginBottom: 5 }}>
                                            <div className="card-header text-capitalize">
                                                {/* <button className='close' onClick={() => subcatDel(row.id)}><Delete/></button> */}
                                                {row.id}
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex flex-wrap">
                                                    {row.data().Values.map((rows) => (
                                                        <div key={rows} className="p-1 w-lg-50">
                                                            <Chip className="w-100 text-capitalize" label={rows}
                                                                onDelete={() => subcatDelete(rows)}
                                                                style={{ fontSize: 18 }} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>

                        {/* <div className="col-lg-6 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col">
                                        <h5>Manage Sub Sub Category</h5>
                                    </div>

                                    <form onSubmit={onSubmit2}>
                                        <select className="form-control-ui fadeIn" name="selcategory">
                                            {newArr.map((row) => (
                                                <option key={row}>{row}</option>
                                            ))}
                                        </select>
                                        <input type="text" className="form-control-ui fadeIn third" name="subcategory" placeholder="Title" required />
                                        {btn && (<>
                                            <input type="submit" className="form-control-btn fadeIn fourth" value="Add Sub Category" />
                                        </>)}
                                        {progbar && (
                                            <div className="col-lg-12">
                                                <center><CircularProgress /></center>
                                            </div>
                                        )}
                                    </form>

                                </div>
                            </div>

                            <br />

                            {subsubcat.map((row) => (
                                <div className="card" key={row.id} style={{ marginBottom: 5 }}>
                                    <div className="card-header">
                                        <button className='close' onClick={() => subsubcatDel(row.id)}><Delete /></button>
                                        {row.id}
                                    </div>
                                    <div className="card-body">

                                        {row.data().Values.map((rows) => (
                                            <>
                                                <Chip label={rows} onDelete={() => subsubcatDelete(row, rows)} style={{ margin: 3, fontSize: 18, textTransform: 'capitalize' }} />
                                            </>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div> */}
                    </div>


                </Box>
            </Box>
        </>
    )
}